import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Backdrop, useTheme } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { FaArrowRightLong } from "react-icons/fa6";
import gif from "../../assets/85490d6827a15ccbace82a79aafbbe6c.gif";
import { capitalize } from "../actions/CommonActions";
import { useNavigate } from "react-router-dom";
import animationData from "../../assets/congratulationsCup.json";
import Lottie from "react-lottie";

export default function AddTeamMemberModal({ open, setOpen, handleInvite, inviteFullName, inviteEmail, inviteRole, setInviteFullName, setInviteEmail, setInviteRole, roles }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "80%", // Adjust the width for small screens
    },
  };

  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            sx: {
              // backgroundColor: '#D0D5DD',
              backdropFilter: "blur(3px)", // Adjust the blur intensity here
            },
          },
        }}
      >
        <Box sx={style}>
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Team member details
          </p>
          <form
          onSubmit={(e)=> {
            setOpen(false);
            handleInvite(e)
          }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>Name</p>
              <input
                type="text"
                required
                value={inviteFullName}
                onChange={(e)=> setInviteFullName(e.target.value)}
                placeholder="Henry Mide"
                style={{
                  border: "1px solid #D0D5DD",
                  padding: "15px 10px",
                  width: "500px",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>Email</p>
              <input
                type="email"
                required
                value={inviteEmail}
                onChange={(e)=> setInviteEmail(e.target.value)}
                placeholder="abc@mail.com"
                style={{
                  border: "1px solid #D0D5DD",
                  padding: "15px 10px",
                  width: "500px",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <p style={{ fontSize: "14px", fontWeight: "500" }}>Role</p>
              <select
                
                onChange={(e)=> setInviteRole(e.target.value)}
                style={{
                  border: "1px solid #D0D5DD",
                  padding: "15px 10px",
                  width: "530px",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              >
                <option>-</option>
                {roles?.map((each, index)=> <option key={index} value={each?.key}>{each?.name}</option>)}
              </select>
            </div>
            <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                <button onClick={handleClose} style={{border: "1px solid #D0D5DD", padding: "10px 35px", borderRadius: "5px", width: "250px", backgroundColor: "white", color: "#344054", fontWeight: "600"}}>Cancel</button>
                <button type="submit" style={{border: "none", padding: "10px 35px", borderRadius: "5px", width: "250px", backgroundColor: "#1038C3", color: "white", fontWeight: "600"}}>Invite</button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
