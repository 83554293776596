import React, { useState } from 'react'
import './header.css'
import Logo2 from "../assets/blackLogo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";


const Header = () => {
  const [openNav, setOpenNav] = useState("HomePage_ShownDetails_NavMenu");
  const [iconOpen, setIconOpen] = useState(false);
  const navigate = useNavigate();


  const handleOpen = () => {
    setIconOpen(true);
    setOpenNav("HomePage_ShownDetails_OpenNavMenu");
  };

  const handleClose = () => {
    setIconOpen(false);
    setOpenNav("HomePage_ShownDetails_NavMenu");
  };
  return (
    <div className="imageHolderLogoQuiz">
      <div className='headerHolderDiv'>
                  <a href='https://www.iquire.co/'>
                    <img
                      src={Logo2}
                      className="logoImgQuizPage"
                    />
                    </a>
                    <div className='tabsDivHolder'>
                      <a href='https://www.iquire.co/about-us' className='linksPageHeader' style={{marginRight: '32px'}}>About Us</a>
                      <a href='https://www.iquire.co/faq' className='linksPageHeader' style={{marginRight: '32px'}}>FAQs</a>
                      <a href='https://www.iquire.co/pricing' className='linksPageHeader' style={{marginRight: '32px'}}>Courses</a>
                      <a href='https://chat.whatsapp.com/KnShBNM9r5eG7YZNRcIMUH' className='linksPageHeader' style={{color: '#0D5EBA'}}>Join Our Community</a>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <a href='https://app.iquire.co/register' class="loginButtonFooter" style={{marginTop: '0px', fontWeight: '600', textDecoration: 'none'}}> Get Started for Free</a>
                    </div>
                    </div>

                    <div className="Homepage_Menudisplay">
        <div className="Homepage_ShownMenuBar">
          <div className="Homepage_headerNavbar">
            <img
              src={Logo2}
              alt="Afrisport logo"
              className="logoImgQuizPage"
            />
            {iconOpen ? (
              <RxCross2 className="Homepage_MeniIcon" onClick={handleClose} />
            ) : (
              <FiMenu className="Homepage_MeniIcon" onClick={handleOpen} />
            )}
          </div>
          <div className={openNav}>
            <Link style={{marginTop:'15px'}} to="https://www.iquire.co/about-us" className="linksPageHeader">
              About Us
            </Link>
            <Link style={{ marginTop:'15px'}} to="https://www.iquire.co/faq" className="linksPageHeader">
               FAQs
            </Link>
            <Link style={{ marginTop:'15px'}} to="https://www.iquire.co/pricing" className="linksPageHeader">
              Courses
            </Link>
            <Link style={{color: '#0D5EBA', marginTop:'15px'}} to="https://chat.whatsapp.com/KnShBNM9r5eG7YZNRcIMUH" className="linksPageHeader">
              Join Our Community
            </Link>
            <Link  style={{marginBottom:'15px', textDecoration: 'none', fontWeight: '600'}} to="https://app.iquire.co/register" className="loginButtonFooter">
            Get Started for Free
            </Link>
          </div>
        </div>
      </div>
                  </div>
  )
}

export default Header