import React, { useEffect, useState } from 'react'
import "./loginConsultant.css"
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from '../components/modals/LoadingModal';
import { notify } from '../components/actions/CommonActions';
import { validateEmailConsultant } from '../services';

const CheckMailreviewCv = () => {


  const [loading, setLoading] = useState(false)
  const [email, setEmail]= useState(null)
  const [data, setData] = useState({
    code: ""
  })
  const navigate = useNavigate()

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setData((prev) => ({...prev, [name]: value }))
  }

  useEffect(()=>{
    const initiate = async () =>{
      const newEmail = await localStorage.getItem("email")
      setEmail(newEmail)
    }
    initiate()
  },[])

  const handleSubmit = async(e) =>{
    e.preventDefault();
    setLoading(true)
    console.log( "login consultant",data);
    data.email = email
    await validateEmailConsultant(data)
    .then(async (res)=>{
      console.log('anon res ', res);
      await localStorage.setItem('session_id', res?.session_id)
      // await localStorage.setItem('userData', res)
      // notify("success", "OTP Sent", res?.message);
      navigate('/set-passwordCv')
    })
    .catch((err)=>{
      // console.log('err res anon ', err);
      notify("danger", "OOPS", err?.response?.data?.message);
    })

    setLoading(false)
  }


  return (
    <div className='loginConsultantAll'>
        <img src={require("../assets/cvReviewLogo.png")} className='imgLogoCvReview' />
        <div className='loginOverViewImgHidden'>
            <form onSubmit={handleSubmit} className='loginViewInputLabelText'>
                <h1 style={{textAlign: "center"}}>Check your mail</h1>
                <p className='textSubLabelHeader'>We sent a password reset link to <span style={{fontWeight: "bold", color: "black"}}>{email}</span></p>
                <p className='inputLabelCvReviewLogin'>Code</p>
                <input name="otp" required onChange={handleChange} className='inputTextCvReviewLogin' type="text" />

                <p></p>

                <button
                  className="loginButton"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Reset password
                </button>
            </form>
            <img src={require("../assets/userIconCvReview.png")} className='userIconCvReview' />
            <img src={require("../assets/usergroupCvReview.png")} className='userGroupCvReview' />
            <LoadingModal open={loading} />
            <div className='backToLoginReviewCvView'>
                <Link to="/login-consultant" className='backToLoginReviewCv'>Back to Login</Link>
            </div>
        </div>
    </div>
  )
}

export default CheckMailreviewCv