import React from "react";
import "./startquiz.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import startQuizImg from "../assets/image-2.png";
import { GoDotFill } from "react-icons/go";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";

const StartQuiz = () => {
  return (
    <div>
      <Header />
      <div className="viewQuizPage">
        <div className="bodyTextHolder">
          <div style={{display: "flex", justifyContent: "center"}}>
          <img src={startQuizImg} width="284px" height="284px" />
          </div>
          <p className="topicStartQuiz">Ready for quiz?</p>
          <p className="startQuizDescText">
            Test yourself on your skill test and see how well you perform.
          </p>
          <p
            className="instructionsParagraph"
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            40 questions <BsDot /> 20 minutes
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "39px",
            }}
          >
            <Link
              className="startQuizButtonQuestionPage"
            //   type="submit"
              to='/question-page'
              style={{textDecoration: "none"}}
              //   disabled={isSubmitting}
            >
              Start quiz
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StartQuiz;
