import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Backdrop, useTheme } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { FaArrowRightLong } from "react-icons/fa6";
import gif from "../../assets/85490d6827a15ccbace82a79aafbbe6c.gif";
import { capitalize } from "../actions/CommonActions";
import { useNavigate } from "react-router-dom";
import animationData from "../../assets/congratulationsCup.json";
import Lottie from "react-lottie";

export default function SkillTestModal({
  open,
  setOpen,
  createResponse,
  result,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/result-quiz", { state: { result: result } });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "80%", // Adjust the width for small screens
    },
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            sx: {
              // backgroundColor: '#D0D5DD',
              backdropFilter: "blur(3px)", // Adjust the blur intensity here
            },
          },
        }}
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={gif} className='gifRegisterSuccessmodal' alt="Description of gif" /> */}
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
          <h1 style={{ textAlign: "center", marginBottom: "5px" }}>
            Congratulations, {capitalize(createResponse?.firstName)}!{" "}
          </h1>
          <div>
            <Typography
              sx={{
                fontSize: "14px",
                width: "80%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              🎉 You've successfully completed this test! 🎉
            </Typography>
            <button
              onClick={() => {
                // if(accountType) navigate('/signup')
                handleClose();
              }}
              className="doneQuestionPageModal"
            >
              View Result <FaArrowRightLong style={{ marginLeft: "8px" }} />
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
