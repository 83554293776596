import React, { useCallback, useState } from 'react'
import './uploadcv.css'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useDropzone } from "react-dropzone"
import { useLocation, useNavigate } from 'react-router-dom'
import { sendUploadInfoApi } from '../services'
import { notify } from '../components/actions/CommonActions'
import LoadingModal from '../components/modals/LoadingModal'

const UploadCVInfoPage = () => {
    const [data, setData] = useState(null)
    const location = useLocation();
    const [loading, setLoading] = useState(false)
  const { state } = location;
  const navigate = useNavigate()

//   console.log("state ", state);

  const handleChange= (e) =>{
    const {name, value} = e.target;
    setData((prev) => ({...prev, [name]: value}))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Convert exp_yr to a number and set other data fields
    data.exp_yr = Number(data.exp_yr);
    data.cv_url = state?.cv_url;
    data.support_info = state?.support_info;
    console.log(data);
  
    // Store email information in session storage
    sessionStorage.setItem('emailInfo', data?.email);
    setLoading(true);
  
    try {
      // Call the API and store the response
      const res = await sendUploadInfoApi(data);
      console.log('res upload ', res?.review_id);
  
      // Check if review_id exists before storing
      if (res?.review_id) {
        sessionStorage.setItem("reviewId", res.review_id);
      } else {
        console.warn("No review_id found in the response");
      }
  
      // Notify the user of a successful submission and navigate
      notify("success", "Submitted", "User information submitted.");
      navigate('/uploadcv-schedule');
    } catch (err) {
      // Handle errors and notify the user
      console.log('err upload ', err);
      notify("danger", "OOPS", err?.response?.data?.message || "Something went wrong.");
    } finally {
      // Set loading to false once the process is complete
      setLoading(false);
    }
  };
  

    

  return (
    <div>
        <Header />
        <div className="viewQuizPage">
        <div className="bodyTextHolderFormQuiz">
        <p className="topicUploadCv" style={{textAlign: 'center'}}>Let’s get some information about you</p>
        <p className='subTextTopicUploadCv'>Fill in your details below</p>

<form onSubmit={handleSubmit}>
        <div className='inputFielUploadCvDiv' style={{marginTop: "40px"}}>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>First Name</p>
            <input type='text' name="first_name" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='Henry'/>
            </div>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>Last Name</p>
            <input type='text' name="last_name" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='Mide'/>
            </div>
        </div>
        <div className='inputFielUploadCvDiv'>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>Email</p>
            <input type='text' name="email" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='abc@mail.com'/>
            </div>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>Phone number</p>
            <input type='text' name="phone" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='08012345678'/>
            </div>
        </div>
        <div className='inputFielUploadCvDiv'>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>Job title</p>
            <input type='text' name="job_title" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='Job title'/>
            </div>
            <div className='divInputIndividualUploadCvInfo'>
            <p style={{color: "#101928"}}>Years of experience</p>
            <input type='number' name="exp_yr" onChange={handleChange} required className='inputTypeFormUploadCvInfo' placeholder='1'/>
            </div>
        </div>

        <div style={{ display: 'flex', justifyContent:"center"}}>
        <button type="submit" className='nextButtonQuestionPage' style={{marginTop: "50px", fontSize: "16px", padding: "10px 70px"}}>Continue to book consultation</button>
        </div>
        </form>
        </div>
        <LoadingModal open={loading} />
        </div>
        <Footer />
        </div>
  )
}

export default UploadCVInfoPage