import React, { useEffect, useState } from 'react'
import "./dashboardConsultant.css";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { CiSearch } from 'react-icons/ci';
import { getAllCvReviews, getAllUserManage } from '../services';
import { notify } from '../components/actions/CommonActions';
import { CircularProgress, capitalize } from '@mui/material';
import moment from 'moment';
import CvReviewInfoModal from '../components/modals/CvReviewInfoModal';
import ReactPaginate from 'react-paginate';

const UserManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allCvReviews, setAllCvReviews] = useState(null)
  const [pageCount, setPageCount] = useState(0); // For total pages
  const [currentPage, setCurrentPage] = useState(1); // Current page



  const [stateTransact, setStateTransact] = React.useState({
    right: false,
  });


  const cvReviewAll = async (page, limit) => {
    await getAllUserManage(page, limit)
      .then(async (res) => {
        // console.log("user management res ", res);
        setAllCvReviews(res)
        setPageCount(res.totalPages);
        // setCvUsers(res);
        // setMetrics(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const initiate = async () => {
      setIsLoading(true);
      await cvReviewAll(1, 10);
      setIsLoading(false);
    };
    initiate();
  }, []);

  const handlePageClick = async (data) => {
    setIsLoading(true);
    let selectedPage = data.selected + 1; // react-paginate uses 0-based index, so add 1
    setCurrentPage(selectedPage); // Update current page state
    await cvReviewAll(selectedPage, 10); // Fetch the new page data
    setIsLoading(false);
  };

  return (
    <>
    {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
    <div className="bodyBackgroundWhole">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <p style={{ fontSize: "24px", fontWeight: "bold" }}>User management</p>
        </div>
        <div></div>
      </div>
      <div
            style={{
              border: "1px solid #E4E7EC",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                padding: "15px 10px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                alignItems: "center",
              }}
            >
              <div className='searchDivHolder' style={{width: "250px"}}>
                    <CiSearch size={20} />
                    <input type="text" placeholder='Search here...' style={{display: "flex", flex: 1, outline: "none", border: "none", background: "transparent", marginLeft: "10px"}} />
                </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Location</th>
                  <th>Course</th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {allCvReviews?.data?.map((each, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: "white", width: "100%" }}
                  >
                    <td>{each?.first_name || each?.full_name?.split(" ")[0]}</td>
                    <td>{each?.last_name || each?.full_name?.split(" ")[1]}</td>
                    <td>{each?.email}</td>
                    <td>{each?.state}, {each?.country}</td>
                    <td>{each?.course}</td>
                  </tr>
                 ))} 
              </tbody>
            </table>
            <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} // Total pages from API
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
          </div>
      </div>
      )
      }

      </>
  )
}

export default UserManagement