import React, { useState } from 'react'
import "./loginConsultant.css"
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from '../components/modals/LoadingModal';
import { notify } from '../components/actions/CommonActions';
import { setPasswordAuth } from '../services';

const SetNewpasswordCv = () => {
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    password: ""
  })
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setData((prev) => ({...prev, [name]: value }))
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true)
    // console.log( "login consultant",data);
    let email = await localStorage.getItem('email')
    let session_id = await localStorage.getItem('session_id')
    if(data?.password == data?.confirm_password){
        setError(false)
        const dataSent = {
            password: data?.password,
            email: email,
            session_id: session_id
        }
        await setPasswordAuth(dataSent)
      .then(async (res) => {
        console.log("anon res ", res);
        notify("success", "Successful", "New password set.");
        navigate("/login-consultant")
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    }
    else{
        setError(true)
    }
    setLoading(false)
  }


  return (
    <div className='loginConsultantAll'>
        <img src={require("../assets/cvReviewLogo.png")} className='imgLogoCvReview' />
        <div className='loginOverViewImgHidden'>
            <form onSubmit={handleSubmit} className='loginViewInputLabelText'>
                <h1 style={{textAlign: "center"}}>Set new password?</h1>
                <p className='textSubLabelHeader'>Ensure your new password is different from the old ones</p>
                <p className='inputLabelCvReviewLogin'>Password</p>
                <div className='passwordDivHolderCvReview'>
                    <input name="password" required onChange={handleChange} type={showPassword? "text":"password"}  className='passwordinputCvReview' />
                    {showPassword ? (
                    <FaRegEye
                      color="#667185"
                      size={18}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      color="#667185"
                      size={18}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>

                <p className='inputLabelCvReviewLogin'>Confirm Password</p>
                <div className='passwordDivHolderCvReview'>
                    <input name="confirm_password" required onChange={handleChange} type={showConfPassword? "text":"password"}  className='passwordinputCvReview' />
                    {showConfPassword ? (
                    <FaRegEye
                      color="#667185"
                      size={18}
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      color="#667185"
                      size={18}
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    />
                  )}
                </div>
                {error && <p style={{color: "red", fontSize: "12px", marginTop: "5px"}}>Password must match.</p>}

                {/* <p></p> */}

                <button
                  className="loginButton"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Reset password
                </button>
            </form>
            <LoadingModal open={loading} />
            <img src={require("../assets/userIconCvReview.png")} className='userIconCvReview' />
            <img src={require("../assets/usergroupCvReview.png")} className='userGroupCvReview' />
            <div className='backToLoginReviewCvView'>
                <Link to="/login-consultant" className='backToLoginReviewCv'>Back to Login</Link>
            </div>
        </div>
    </div>
  )
}

export default SetNewpasswordCv