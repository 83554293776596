import React, { useEffect, useState } from 'react'
import "./root.css"
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { FaHouse, FaRegBell } from 'react-icons/fa6'
import { MdOutlineListAlt } from "react-icons/md";
import { IoPerson, IoSettingsOutline } from 'react-icons/io5';
import { RxExit } from 'react-icons/rx';
import { CiSearch } from "react-icons/ci";

const Root = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = localStorage.getItem("userData");
    setUser(JSON.parse(userInfo));
    const isAuth = localStorage.getItem("token");
    !isAuth && navigate("/login-consultant");
    // !isAuth ? navigate("/all-mandates") : navigate("/login");
  }, []);

  // console.log("userinfo ", user);

  return (
    <div className='rootOverallDiv'>
        <div className="navbarView">
            <div style={{display: "flex", flexDirection: "column"}}>
            <img src={require('../assets/cvReviewLogo.png')} width="92px" height="32px" style={{marginLeft: "10px", marginBottom: "10px", marginTop: "20px"}} />
            <NavLink
                to={"/dashboard-consultant"}
                className=" navlinkDashboardConsultant"
              >
                <FaHouse /> <p style={{marginLeft: "10px"}}>Dashboard</p>
              </NavLink>
              <NavLink
                to={"/cv-consultant"}
                className=" navlinkDashboardConsultant"
              >
                <MdOutlineListAlt /> <p style={{marginLeft: "10px"}}>CV Consultation</p>
              </NavLink>
              <NavLink
                to={"/user-management"}
                className=" navlinkDashboardConsultant"
              >
                <IoPerson /> <p style={{marginLeft: "10px"}}>User Management</p>
              </NavLink>
            </div>
            <div style={{display: "flex", flexDirection: "column", marginBottom: "20px"}}>
            <NavLink
                to={"/settings-consultant"}
                className=" navlinkDashboardConsultant"
                style={{marginBottom: "20px"}}
              >
                <IoSettingsOutline /> <p style={{marginLeft: "10px"}}>Settings</p>
              </NavLink>
              <div style={{display: "flex", alignItems:"center"}}>
                {!user?.user?.profilePicture && <img src={require("../assets/profilePic.png")} width="45px" height="45px" />}
                {user?.user?.profilePicture && <img src={user?.user?.profilePicture} width="45px" height="45px"  style={{borderRadius : "50%"}}/>}
                <div style={{marginLeft: "10px"}}>
                    <p style={{fontWeight: 'bold', fontSize: "14px"}}>{user?.user?.fullName}</p>
                    <p style={{ fontSize: "14px"}}>{user?.user?.email.slice(0,5)+ "..." + user?.user?.email.slice(-15) }</p>
                </div>
                <RxExit style={{cursor: "pointer", marginLeft: "15px"}} size={22} onClick={async() => {
                      await localStorage.removeItem("token");
                      await localStorage.removeItem("userData");
                      navigate("/login-consultant");
                    }} />
              </div>
            </div>
        </div>
        <div style={{flex: 1, height: "100%"}}>
            <div className='searchOveralViewCvConsultant'>
                {/* <div className='searchDivHolder'>
                    <CiSearch size={20} />
                    <input type="text" placeholder='Search here...' style={{display: "flex", flex: 1, outline: "none", border: "none", background: "transparent", marginLeft: "10px"}} />
                </div> */}
                <div></div>
                <div style={{display: "flex"}}>
                    <div style={{padding: "5px 10px", backgroundColor: "#F0F2F5", marginRight: "20px", borderRadius: "10px"}}>
                        <FaRegBell size={18} />
                    </div>
                {!user?.user?.profilePicture && <img src={require("../assets/profilePic.png")} width="35px" height="35px" />}
                {user?.user?.profilePicture && <img src={user?.user?.profilePicture} width="35px" height="35px" style={{borderRadius : "50%"}} />}
                </div>
            </div>
            {/* <div className='bodyBackgroundWhole'>
                <p>tunde</p>
            </div> */}
            <Outlet />
        </div>
    </div>
  )
}

export default Root