import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Backdrop, useTheme } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { FaArrowRightLong } from "react-icons/fa6";
import gif from "../../assets/85490d6827a15ccbace82a79aafbbe6c.gif";
import { capitalize } from "../actions/CommonActions";
import { useNavigate } from "react-router-dom";
import animationData from "../../assets/congratulationsCup.json";
import Lottie from "react-lottie";

export default function DeactivateUser({ open, setOpen, handleInvite}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "80%", // Adjust the width for small screens
    },
  };

  

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            sx: {
              // backgroundColor: '#D0D5DD',
              backdropFilter: "blur(3px)", // Adjust the blur intensity here
            },
          },
        }}
      >
        <Box sx={style}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <img src={require('../../assets/caution.png')} width="50px" height="50px" />
                <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Deactivate user?
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
            //   fontWeight: "bold",
              marginTop: "10px",
              color: "#667185"
            }}
          >
            Are you sure you want to deactivate this user?
          </p>
          <div style={{marginTop: "10px"}}>
            <button style={{border: "2px solid #034592", backgroundColor: "white", padding: "10px 50px", color: "#034592", fontWeight: "bold", fontSize: "15px", borderRadius: "5px"}}>Cancel</button>
            <button onClick={handleInvite} style={{border: "none", backgroundColor: "#034592", padding: "12px 50px", color: "white", fontWeight: "bold", fontSize: "15px", borderRadius: "5px", marginLeft: "20px"}}>Continue</button>
          </div>
            </div>
         
        </Box>
      </Modal>
    </div>
  );
}
