import React, { useEffect, useState } from "react";
import "./questionpage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import SkillTestModal from "../components/modals/SkillTestModal";
import { questionsApi } from "../services";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../components/modals/LoadingModal";

const QuestionPage = () => {
  const [open, setOpen] = React.useState(false);
  const [createResponse, setCreateResponse] = React.useState(null);
  const [page, setPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [minutes, setMinutes] = useState(20);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [questionResponse, setQuestionResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questionsNo, setQuestionsNo] = useState(1);
  const [questionsOnPage, setQuestionsOnPage] = useState(3);
  const [totalQuestions, setTotalQuestions] = useState(3)
  const [selectedOption, setSelectedOption] = useState([]);
  const [questionsPerPage] = useState(3); // Number of questions per page
  const [allQuestions, setAllQuestions] = useState([]); // Store all questions
  const [questionShown, setQuestionsShown] = useState([]); // Subset for current page

  // useEffect(() => {
  //   const initiate = async () => {
  //     let testId = await localStorage.getItem("sessionIdSkillQuiz");
  //     let value = {
  //       test_id: testId,
  //     };
  //     setLoading(true);
  //     await questionsApi(value, page)
  //       .then((res) => {
  //         //   console.log("res question ", res);
  //         setQuestionResponse(res);
  //         setAllQuestions((prevQuestions) => [...prevQuestions, ...res?.data])
  //         if (page > 1) {
  //           if (page > previousPage) {
  //             setQuestionsNo(questionsNo + 3);
  //             setQuestionsOnPage(questionsOnPage + 3);
  //           } else if (page < previousPage) {
  //             setQuestionsNo(questionsNo - 3);
  //             setQuestionsOnPage(questionsOnPage - 3);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err question ", err);
  //       });
  //     setLoading(false);
  //   };
  //   initiate();
  // }, [page]);

  useEffect(() => {
    const initiate = async () => {
      // Only call the API if the current page's questions are not in allQuestions
      const isPageFetched = allQuestions.length >= page * questionsOnPage;
      let limit
      
      if (!isPageFetched) {
        let testId = await localStorage.getItem("sessionIdSkillQuiz");
        let value = { test_id: testId };
        if(page== 17){
          limit = 2
        }
        else{
          limit = 3
        }
        setLoading(true);
        await questionsApi(value, page, limit)
          .then((res) => {
            setQuestionResponse(res);
            // Append new questions to the already fetched questions
            setAllQuestions((prevQuestions) => [...prevQuestions, ...res?.data]);
          })
          .catch((err) => {
            console.log("err question ", err);
          });
        setLoading(false);
      } 
      else {
        // If questions for the current page have already been fetched, no need to fetch again
        console.log('Using previously fetched questions');
      }
    };
  
    // Only initiate fetch if we're on a new page (incrementing)
    initiate();
  }, [page]);

  // Update questions on each page change
  useEffect(() => {
    const startIndex = (page - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    setQuestionsShown(allQuestions.slice(startIndex, endIndex)); // Set questions for the current page
  }, [page, allQuestions, questionsPerPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }

      if (seconds == 0 && minutes == 0) {
        navigate("/form-quiz", { state: { selectedOption: selectedOption } });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleNextPage = async () => {
    console.log('selectedOption ',selectedOption);
    setPreviousPage(page);
    setTotalQuestions(totalQuestions + 3)
    console.log('page ', page);
    setPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPreviousPage(page);
      setTotalQuestions(totalQuestions - 3)
      setPage(page - 1);  // Only decrement the page
    }
  };

  function checkQuestionIdExists(array, questionId) {
    return array.some(item => item.questionId === questionId);
  }

  function removeQuestionoption(array, questionId, option) {
    return array.map((item) => {
      if (item.questionId === questionId) {
        const updatedAnswers = item.answers.filter(each => each !== option);
  
        // If there are other answers left, return the updated item
        if (updatedAnswers.length > 0) {
          return {
            ...item,
            answers: updatedAnswers,
          };
        } else {
          // Otherwise, remove the question entirely from the selected options
          return null;
        }
      }
      return item;
    }).filter(item => item !== null); // Filter out null items
  }
  

  function updateAnswersArray(array, questionId, newAnswers) {
    return array.map(item => {
      if (item.questionId === questionId) {
        return {
          ...item,
          answers: [newAnswers]
        };
      }
      return item;
    });
  }

  function updateMultipleSelectionAnswersArray(array, questionId, newAnswer) {
    return array.map(item => {
      if (item.questionId === questionId) {
        // Check if the new answer is already selected
        const updatedAnswers = item.answers.includes(newAnswer)
          ? item.answers // If the answer is already selected, do nothing
          : [...item.answers, newAnswer]; // Otherwise, add the new answer to the array
  
        return {
          ...item,
          answers: updatedAnswers,
        };
      }
      return item;
    });
  }
  

  const handleOptionChange = (questionId, option, categoryKey) => {
    const exists = checkQuestionIdExists(selectedOption, questionId);
    
    if (exists) {
      const currentAnswers = selectedOption.find(
        each => each.questionId === questionId
      )?.answers;
  
      // Check if the clicked option is already selected
      if (currentAnswers?.includes(option)) {
        // Deselect the option by removing it
        const updatedArray = removeQuestionoption(selectedOption, questionId, option);
        setSelectedOption(updatedArray);
      } else {
        // Otherwise, update the answers with the new selected option
        const updatedArray = updateAnswersArray(selectedOption, questionId, option);
        setSelectedOption(updatedArray);
      }
    } else {
      // If the questionId doesn't exist in selectedOption, add it
      setSelectedOption((prev) => [
        ...prev,
        {
          questionId: questionId,
          answers: [option],
          category: categoryKey,
        },
      ]);
    }
  };

  const handleOptionMultipleSelectionChange = (questionId, option, categoryKey) => {
    const exists = checkQuestionIdExists(selectedOption, questionId);
    
    if (exists) {
      const currentAnswers = selectedOption.find(
        each => each.questionId === questionId
      )?.answers;
  
      // Check if the clicked option is already selected
      if (currentAnswers?.includes(option)) {
        // Deselect the option by removing it
        const updatedArray = removeQuestionoption(selectedOption, questionId, option);
        setSelectedOption(updatedArray);
      } else {
        // Otherwise, update the answers with the new selected option
        const updatedArray = updateMultipleSelectionAnswersArray(selectedOption, questionId, option);
        setSelectedOption(updatedArray);
      }
    } else {
      // If the questionId doesn't exist in selectedOption, add it
      setSelectedOption((prev) => [
        ...prev,
        {
          questionId: questionId,
          answers: [option],
          category: categoryKey,
        },
      ]);
    }
  };
  

  function isOptionSelected(selectedOptions, questionId, option) {
    return selectedOptions[questionId]?.answers?.includes(option);
  }

  const handleSubmitPage = () =>{
    navigate('/form-quiz', { state: { selectedOption: selectedOption } });
  }

//   console.log('exists ', selectedOption?.find(item => item?.questionId == "66e34e7c2836d7865fe46c7f")?.answers?.includes("I analyzed data to identify patterns and used them to optimize processes."));
// console.log('question shown ', questionShown);
  return (
    <div>
      <Header />
      <div className="bodyTextHolder">
        {questionShown?.map((item, index) => (
          <div key={index}>
            <div className="questionTimeDivHolder">
            <p className="questionText">
  Question {(page - 1) * questionsOnPage + (index + 1)} of{" "}
  {questionResponse?.totalQuestions}
</p>
              <p className="stopTimeText">
                <span className="questionText">Time left:</span>{" "}
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            </div>

            <p className="questionTesttoAnswer">{item?.questionText}</p>

            {item?.isMultipleChoice && (
              <>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_a}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_a")}
                    // onChange={() => handleOptionChange(item?._id , "option_a", item?.categoryKey)}
                    onClick={() => handleOptionChange(item?._id , "option_a", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>A.</span>{" "}
                    {item?.option_a}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_b}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_b")}
                    onClick={() => handleOptionChange(item?._id , "option_b", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>B.</span>{" "}
                    {item?.option_b}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_c}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_c")}
                    onClick={() => handleOptionChange(item?._id , "option_c", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>C.</span>{" "}
                    {item?.option_c}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_d}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_d")}
                    onClick={() => handleOptionChange(item?._id ,"option_d", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>D.</span>{" "}
                    {item?.option_d}
                  </p>
                </div>
              </>
            )}

{item?.isMultipleSelect && (
              <>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_a}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_a")}
                    // onChange={() => handleOptionChange(item?._id , "option_a", item?.categoryKey)}
                    onClick={() => handleOptionMultipleSelectionChange(item?._id , "option_a", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>A.</span>{" "}
                    {item?.option_a}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_b}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_b")}
                    onClick={() => handleOptionMultipleSelectionChange(item?._id , "option_b", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>B.</span>{" "}
                    {item?.option_b}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_c}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_c")}
                    onClick={() => handleOptionMultipleSelectionChange(item?._id , "option_c", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>C.</span>{" "}
                    {item?.option_c}
                  </p>
                </div>
                <div className="AnswerToQuestionPage">
                  <input
                    type="radio"
                    value={item?.option_d}
                    checked={selectedOption?.find(each => each?.questionId == item?._id)?.answers?.includes("option_d")}
                    onClick={() => handleOptionMultipleSelectionChange(item?._id ,"option_d", item?.categoryKey)}
                  />
                  <p className="answerOptionQuestionPage">
                    <span style={{ fontWeight: "bold" }}>D.</span>{" "}
                    {item?.option_d}
                  </p>
                </div>
              </>
            )}

            <div className="horizontalLineQuestionPage"></div>
          </div>
        ))}
        <div className="buttonsQuestionsPage">
          {page > 1 && (
            <button
              onClick={handlePreviousPage}
              className="previousButtonQuestionPage"
            >
              {" "}
              <FaArrowLeft style={{ marginRight: "8px" }} /> Previous
            </button>
          )}
          <div></div>
          {totalQuestions >= questionResponse?.totalQuestions ? (
            <button
              // onClick={() => setOpen(true)}
              onClick={handleSubmitPage}
              className="nextButtonQuestionPage"
            >
              Submit <FaArrowRight style={{ marginLeft: "8px" }} />
            </button>
          ) : (
            <button onClick={handleNextPage} className="nextButtonQuestionPage">
              Next <FaArrowRight style={{ marginLeft: "8px" }} />
            </button>
          )}
        </div>
      </div>
      <SkillTestModal
        open={open}
        setOpen={setOpen}
        createResponse={createResponse}
      />

      <LoadingModal open={loading} />

      <Footer />
    </div>
  );
};

export default QuestionPage;
