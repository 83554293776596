import React, { useEffect, useState } from 'react'
import "./dashboardConsultant.css";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { CiSearch } from 'react-icons/ci';
import { doneConsultation, doneCvReview, getAllCvReviews } from '../services';
import { notify } from '../components/actions/CommonActions';
import { CircularProgress, capitalize } from '@mui/material';
import moment from 'moment';
import CvReviewInfoModal from '../components/modals/CvReviewInfoModal';
import ReactPaginate from 'react-paginate';

const CVConsultant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allCvReviews, setAllCvReviews] = useState(null)
  const [details, setDetails] = useState(null)
  const [pageCount, setPageCount] = useState(0); // For total pages
  const [currentPage, setCurrentPage] = useState(1); // Current page



  const [stateTransact, setStateTransact] = React.useState({
    right: false,
  });
  const anchor = "right";

  const toggleDrawerTransact = (anchor, open) => (event = {}) => {
    // Ensure the event is properly handled even if it's not passed
    if (
      event?.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateTransact({ ...stateTransact, [anchor]: open });
  };

  const cvReviewAll = async (page, limit) => {
    await getAllCvReviews(page, limit)
      .then(async (res) => {
        // console.log("cv reviews res ", res);
        setAllCvReviews(res)
        setPageCount(res.totalPages);
        // setCvUsers(res);
        // setMetrics(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const initiate = async () => {
      setIsLoading(true);
      await cvReviewAll(1, 10);
      setIsLoading(false);
    };
    initiate();
  }, []);

  const handlePageClick = async (data) => {
    setIsLoading(true);
    let selectedPage = data.selected + 1; // react-paginate uses 0-based index, so add 1
    setCurrentPage(selectedPage); // Update current page state
    await cvReviewAll(selectedPage, 10); // Fetch the new page data
    setIsLoading(false);
  };

  const handleDoneConsultation = async () =>{
    let sentData = {
      meeting_log_id: details?.meeting_log[0]?._id
    }
    await toggleDrawerTransact(anchor, false)()
    setIsLoading(true);
    await doneConsultation(sentData)
      .then(async (res) => {
        console.log("meeting done res ", res);
        // setCvUsers(res);
        // setMetrics(res)
        notify("success", "Consultation done", res?.message);
        await cvReviewAll(1, 10);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
      setIsLoading(false)
  }

  const handleDoneCvReview = async () =>{
    let sentData = {
      review_id: details?._id
    }
    await toggleDrawerTransact(anchor, false)()
    setIsLoading(true);
    await doneCvReview(sentData)
      .then(async (res) => {
        console.log("meeting done res ", res);
        // setCvUsers(res);
        // setMetrics(res)
        notify("success", "Cv review done", res?.message);
        await cvReviewAll(1, 10);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
      setIsLoading(false)
  }


  return (
    <>
    {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
    <div className="bodyBackgroundWhole">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <p style={{ fontSize: "24px", fontWeight: "bold" }}>Consultation</p>
        </div>
        <div></div>
      </div>
      <div
            style={{
              border: "1px solid #E4E7EC",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                padding: "15px 10px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                alignItems: "center",
              }}
            >
              <div className='searchDivHolder' style={{width: "250px"}}>
                    <CiSearch size={20} />
                    <input type="text" placeholder='Search here...' style={{display: "flex", flex: 1, outline: "none", border: "none", background: "transparent", marginLeft: "10px"}} />
                </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th>Name</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Review Status</th>
                  <th>Consultation meeting</th>
                  <th>Plan type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {allCvReviews?.data?.map((each, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: "white", width: "100%" }}
                  >
                    <td>{each?.user_id?.first_name} {each?.user_id?.last_name}</td>
                    <td>{each?.user_id?.email}</td>
                    <td>{moment(each?.user_id?.createdAt).format("MMM DD, YYYY")}</td>
                    <td><span style={{padding: "5px", backgroundColor: each?.status == "initiated"? "#E3EFFC" : each?.status == "pending"? "#F0F2F5" : "#E7F6EC", borderRadius: "10px", color: each?.status == "initiated"? "#04326B": each?.status == "pending"? "#344054": "#036B26", fontWeight: "500"}}>{capitalize(each?.status == "initiated" ? "Pending" : "Done")}</span></td>
                    <td><span style={{padding: "5px", backgroundColor: each?.meeting_log[0]?.consultation_status == "initiated"? "#F0F2F5" : "#E7F6EC", borderRadius: "10px", color: each?.meeting_log[0]?.consultation_status == "initiated"? "#344054": "#036B26", fontWeight: "500"}}>{capitalize(each?.meeting_log[0]?.consultation_status == "initiated" ? "New" : "Done")}</span></td>
                    <td>{each?.user_id?.plan_id?.plan_type}</td>
                    <td><span onClick={async()=>{
                      setDetails(each)
                       toggleDrawerTransact(anchor, true)()
                    }} style={{color: "#0D5EBA", fontWeight: "500", cursor: "pointer"}}>View more</span></td>
                  </tr>
                 ))} 
              </tbody>
            </table>
            <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} // Total pages from API
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
          </div>
          <CvReviewInfoModal state={stateTransact} toggleDrawer={toggleDrawerTransact} anchor={anchor} details={details} handleDoneConsultation={handleDoneConsultation} handleDoneCvReview={handleDoneCvReview} />
      </div>
      )
    }
      </>
  )
}

export default CVConsultant