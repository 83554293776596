import React from "react";
import Footer from "../components/Footer";
import "./quizpage.css";
import Logo2 from "../assets/blackLogo.png";
import { GoDotFill } from "react-icons/go";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const QuizPage = () => {
  // const instruction = [
  //   "Do well to read and follow these instructions carefully to get optimum results.",
  //   "Click on the proceed button directly below.",
  //   "Select the experience level that applies to you and click on next (NOTE: YOU CAN NOT MOVE FORWARD WITHOUT THIS).        ",
  //   "Now you are ready to start, you will be given 40 questions to answer in 30 minutes        ",
  //   "Click on START QUIZ",
  //   "Read and answer all questions carefully and before the time runs out then click on SUBMIT.",
  //   "Fill in your details on the next page and click on next.",
  //   "Seat patiently and wait for your result.",
  // ];

  const instruction = [
    "⁠Prepare Your Environment: Find a quiet, comfortable place to take the quiz, Ensure you have a stable internet connection., Have a notepad and pen handy for any rough work.",
    "Read Each Question Carefully: Take your time to understand each question before answering, Avoid rushing to ensure accuracy.",
    "Manage Your Time: Keep an eye on the timer, If you’re stuck on a question, move on and come back to it later if time allows.",
    "Submit Your Quiz: The quiz will auto-submit once the timer runs out, Make sure to review your answers if time permits before the quiz ends."
  ]

  const whyTake = [
    "Showcase Your Skills: Prove your capabilities to potential employers.      ",
    "⁠Identify Strengths and Weaknesses: Understand where you excel and areas for improvement.      ",
    "Tailored Feedback: Receive personalized insights based on your performance.",
  ];

  const ChooseLevel = [
    "Entry-Level Job Seekers: Perfect for recent graduates or those with less than 2 years of experience. Assessments are designed to evaluate foundational skills and knowledge.",
    "Advanced Job Seekers: Ideal for professionals with 2+ years of experience. Assessments are focus on advanced skills and experience working with in the cooperate sector."
  ]

  const howItworks= [
    "⁠Select Your Category: Choose whether you are an entry-level or advanced job seeker.    ",
    "Start the Quiz: Begin your timed assessment. Ensure you have a quiet, distraction-free environment.    ",
    "Complete the Quiz: Answer all questions to the best of your ability. The quiz will automatically end when the time is up    ",
    "Receive Feedback: Get your results instantly, along with detailed feedback and areas for improvement.    "
  ]

  const faq= [
    "How long is the quiz: The quiz duration varies depending on the level but typically ranges between 10 to 15 minutes.",
    "Can I retake the quiz: Yes, you can retake the quiz after a 4 hour cooling-off period.",
    "How is my performance evaluated: Your performance is evaluated based on accuracy, speed, and the complexity of questions answered correctly.",
    "Will I receive a email to show I took this Quiz: Yes, you will receive the result of assessment via email"
  ]
  return (
    <div>
      <Header />
      <div className="viewQuizPage">
        <p className="welcomeText">Welcome to your skill assessment test</p>
        <div className="bodyTextHolder">
          <p className="textColorQuiz">
            Unlock your potential and take your career to the next level with
            our tailored skill assessments. Whether you're an entry-level job
            seeker or an experienced professional, our quizzes are designed to
            evaluate your abilities and help you stand out in the job market.
          </p>
        </div>

        <div className="bodyTextHolder">
          <p
            className="instructionsParagraph"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Why Take Our Skill Assessment?
          </p>
          {whyTake?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}

<p
            className="instructionsParagraph"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Choose Your Assessment Level
          </p>
          {ChooseLevel?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}

<p
            className="instructionsParagraph"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            How It Works
          </p>
          {howItworks?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}

          <p
            className="instructionsParagraph"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Instructions
          </p>
          {instruction?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}
          <p
            className="instructionsParagraph"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            Frequently Asked Questions (FAQs)
          </p>
          {faq?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}
        </div>
        <Link
          className="quizButton"
          // type="submit"
          to="/question-category"
          //   disabled={isSubmitting}
        >
          Proceed
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default QuizPage;
