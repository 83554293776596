import React, { useState } from 'react'
import "./loginConsultant.css"
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from '../components/modals/LoadingModal';
import { notify } from '../components/actions/CommonActions';
import { forgotPasswordConsultant } from '../services';

const ForgotPasswordCvReview = () => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    email: ""
  })
  const navigate = useNavigate()

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setData((prev) => ({...prev, [name]: value }))
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true)
    // console.log( "login consultant",data);
    await forgotPasswordConsultant(data)
    .then(async (res)=>{
      console.log('anon res ', res);
      await localStorage.setItem('email', data?.email)
      // await localStorage.setItem('userData', res)
      notify("success", "OTP Sent", res?.message);
      navigate('/check-mailCv')
    })
    .catch((err)=>{
      // console.log('err res anon ', err);
      notify("danger", "OOPS", err?.response?.data?.message);
    })
    setLoading(false)
  }


  return (
    <div className='loginConsultantAll'>
        <img src={require("../assets/cvReviewLogo.png")} className='imgLogoCvReview' />
        <div className='loginOverViewImgHidden'>
            <form onSubmit={handleSubmit} className='loginViewInputLabelText'>
                <h1 style={{textAlign: "center"}}>Forgot password?</h1>
                <p className='textSubLabelHeader'>We’ll send you reset instructions</p>
                <p className='inputLabelCvReviewLogin'>Email Address</p>
                <input name="email" required onChange={handleChange} className='inputTextCvReviewLogin' type="email" placeholder='example@email.com' />

                <p></p>

                <button
                  className="loginButton"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Reset password
                </button>
            </form>
            <LoadingModal open={loading} />
            <img src={require("../assets/userIconCvReview.png")} className='userIconCvReview' />
            <img src={require("../assets/usergroupCvReview.png")} className='userGroupCvReview' />
            <div className='backToLoginReviewCvView'>
                <Link to="/login-consultant" className='backToLoginReviewCv'>Back to Login</Link>
            </div>
        </div>
    </div>
  )
}

export default ForgotPasswordCvReview