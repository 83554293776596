import React from "react";
import "./startquiz.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import startQuizImg from "../assets/startQuiz.png";
import { GoDotFill } from "react-icons/go";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/successPayment.json";

const SuccessfulPricingOptionUploadCv = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

  return (
    <div>
      <Header />
      <div className="viewQuizPage">
        <div className="bodyTextHolder">
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={gif} className='gifRegisterSuccessmodal' alt="Description of gif" /> */}
            <Lottie options={defaultOptions} height={300} width={400} />
          </div>
          <p className="topicStartQuiz">Successful payment!</p>
          <p className="startQuizDescText">
          Lorem ipsum dolor sit amet consectetur. Ut dolor suscipit commodo ut ultrices ullamcorper erat. Id euismod non.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "39px",
            }}
          >
            <Link
              className="startQuizButtonQuestionPage"
            //   type="submit"
              to='https://www.iquire.co/'
              style={{textDecoration: "none"}}
              //   disabled={isSubmitting}
            >
              Continue to homepage
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SuccessfulPricingOptionUploadCv;
