import logo from './logo.svg';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Button } from 'react-bootstrap';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './pages/login';
import CreateAccount from './pages/createaccount';
import SignUp from './pages/SignUp';
import GetStarted from './pages/GetStarted';
import QuizPage from './pages/QuizPage';
import QuestionPage from './pages/QuestionPage';
import QuizCategoryPage from './pages/QuizCategoryPage';
import StartQuiz from './pages/StartQuiz';
import FormQuiz from './pages/FormQuiz';
import QuizResult from './pages/QuizResult';
import UploadCV from './pages/UploadCV';
import UploadCVInfoPage from './pages/UploadCVInfoPage';
import ScheduleUploadCv from './pages/ScheduleUploadCv';
import UploadCVPricingOptions from './pages/UploadCVPricingOptions';
import SuccessfulPricingOptionUploadCv from './pages/SuccessPricingOptionUploadCv';
import LoginConsultant from './pages/LoginConsultant';
import ForgotPasswordCvReview from './pages/ForgotPasswordCvReview';
import CheckMailreviewCv from './pages/CheckMailreviewCv';
import SetNewpasswordCv from './pages/SetNewpasswordCv';
import DashboardConsultant from './pages/DashboardConsultant';
import Root from './components/Root';
import { ErrorPage } from './pages/ErrorPage';
import CVConsultant from './pages/CVConsultant';
import SettingsConsultant from './pages/SettingsConsultant';
import SetPasswordNewUser from './pages/SetPasswordNewUser';
import UserManagement from './pages/UserManagement';

function App() {

  const router = createBrowserRouter([
    {
      // path: "/",
      // element: <Root />,
      // errorElement: <ErrorPage />,
      children: [
        // {
        //   path: "dashboard",
        //   element: <Dashboard />,
        // },
      ],
    },
    // {
    //   path: "login",
    //   element: <Login />,
    // },
    // {
    //   path: "/create-account",
    //   element: <CreateAccount />,
    // },
    // {
    //   path: "/signup",
    //   element: <SignUp />,
    // },
    {
      path: "/register",
      element: <GetStarted />,
    },
    {
      path: "/quiz",
      element: <QuizPage />,
    },
    {
      path: "/question-page",
      element: <QuestionPage />
    },
    {
      path: "/question-category",
      element: <QuizCategoryPage />
    },
    {
      path: "/start-quiz",
      element: <StartQuiz />
    },
    {
      path: "/form-quiz",
      element: <FormQuiz />
    },
    {
      path: "/result-quiz",
      element: <QuizResult />
    },
    {
      path: "/upload-cv",
      element: <UploadCV />
    },
    {
      path: "/uploadcv-info",
      element: <UploadCVInfoPage />
    },
    {
      path: "/uploadcv-schedule",
      element: <ScheduleUploadCv />
    },
    {
      path: "/uploadcv-pricing",
      element: <UploadCVPricingOptions />
    },
    // {
    //   path: "/uploadcv-success",
    //   element: <SuccessfulPricingOptionUploadCv />
    // },
    {
      path: "/api/v1/iquire/payment/callback",
      element: <SuccessfulPricingOptionUploadCv />
    },
    {
      path: "/login-consultant",
      element: <LoginConsultant />
    },
    {
      path: "/forgot-passwordCv",
      element: <ForgotPasswordCvReview />
    },
    {
      path: "/check-mailCv",
      element: <CheckMailreviewCv />
    },
    {
      path: "/set-passwordCv",
      element: <SetNewpasswordCv />
    },
    {
      path: "/set-password",
      element: <SetPasswordNewUser />
    },
    // {
    //   path: "/dashboard-consultant",
    //   element: <DashboardConsultant />
    // },
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/dashboard-consultant",
          element: <DashboardConsultant />,
        },
        {
          path: "/cv-consultant",
          element: <CVConsultant />,
        },
        {
          path: "/settings-consultant",
          element: <SettingsConsultant />,
        },
        {
          path: "/user-management",
          element: <UserManagement />,
        },
      ]
    }
  ]);

  const handleRouteChange = () => {
    console.log(window.location.pathname);
    sessionStorage.setItem("currentRoute", window.location.pathname);
  };


  return (
    <div className="App">
      <RouterProvider router={router} onChange={handleRouteChange} />
    </div>
  );
}

export default App;
