import { Box, Drawer } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { BsPeople, BsPerson } from 'react-icons/bs'
import { CiCalendarDate, CiCreditCard1, CiMail } from 'react-icons/ci'
import { FiPhone } from 'react-icons/fi'
import { HiOutlineGlobe } from 'react-icons/hi'

const CvReviewInfoModal = ({ anchor,
    state,
    toggleDrawer,
    details,
    handleDoneConsultation,
    handleDoneCvReview
}) => {

    // console.log("details ", details);

  return (
    <div>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
            overflowY: "scroll",
            padding:"20px"
          }}
          role="presentation"
          className="p-4"
        >
          <p style={{ color: "black", fontSize: "25px", fontWeight:"600", marginBottom: "20px" }}>
            View details
          </p>
          <div style={{width: "100%", borderTop: "1px solid #D0D5DD", display: "flex", flexDirection: "row", padding: "15px 0", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <BsPerson color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Full Name</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{details?.user_id?.first_name} {details?.user_id?.last_name}</p>
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CiMail color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Email</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{details?.user_id?.email}</p>
                </div>
                </div>
          </div>
          <div style={{width: "100%", borderTop: "1px solid #D0D5DD", display: "flex", flexDirection: "row", padding: "15px 0", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <FiPhone color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Google meet</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>
                    {details?.meeting_log?.length > 0 && <a href={details?.meeting_log[0]?.consultation_url} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
    Click here
  </a>}
                    </p>
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <HiOutlineGlobe color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Job Title</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{details?.user_id?.job_title}</p>
                </div>
                </div>
          </div>
          <div style={{width: "100%", borderTop: "1px solid #D0D5DD", display: "flex", flexDirection: "row", padding: "15px 0", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <BsPeople color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Years of Experience</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{details?.user_id?.exp_yr}</p>
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CiCalendarDate color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Consultation date and time</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{moment(details?.meeting_log[0]?.consultation_date).format("MMM DD, YYYY  hh:mmA")}</p>
                </div>
                </div>
          </div>
          <div style={{width: "100%", borderTop: "1px solid #D0D5DD", display: "flex", flexDirection: "row", padding: "15px 0", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <CiCreditCard1 color="#98A2B3" size="20px" />
                <div style={{marginLeft: "20px"}}>
                    <p style={{color: "#475467", fontSize: "12px"}}>Plan type</p>
                    <p style={{color: "#475467", fontWeight: "500", marginTop: "5px"}}>{details?.user_id?.plan_id?.plan_type}</p>
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                
                </div>
          </div>
          <div style={{marginTop: "20px", height: "30vh", flexDirection: "column"}}>
                    <p style={{color: "#475467", fontSize: "12px", marginBottom: "5px"}}>Additional information</p>
                    <p>{details?.cv_file_id?.support_info}</p>
                    </div>

          <div style={{width: "100%", borderTop: "1px solid #D0D5DD", padding: "10px 0"}}>
            <div style={{width: "100%", display: "flex", flex: 1, flexDirection: "column"}}>
            <a href={details?.cv_file_id?.cv_url} style={{border: "none", backgroundColor: "#1671D9", padding: "15px 0 ", color: "white", fontSize: "16px", width: "100%", fontWeight: "bold", borderRadius: "40px", cursor: "pointer", textDecoration: "none", textAlign:"center"}} download>Download resume</a>
            <button onClick={handleDoneConsultation} style={{border: "none", backgroundColor: "red", padding: "15px 0 ", color: "white", fontSize: "16px", width: "100%", fontWeight: "bold", borderRadius: "40px", cursor: "pointer", textDecoration: "none", textAlign:"center", marginTop: "10px"}}>Mark meeting as done</button>
            <button onClick={handleDoneCvReview} style={{border: "none", backgroundColor: "red", padding: "15px 0 ", color: "white", fontSize: "16px", width: "100%", fontWeight: "bold", borderRadius: "40px", cursor: "pointer", textDecoration: "none", textAlign:"center", marginTop: "10px"}}>Mark cv review as done</button>
            </div>
          </div>

        </Box>
      </Drawer>
    </div>
  )
}

export default CvReviewInfoModal