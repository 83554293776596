import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import { GoDotFill } from "react-icons/go";
import { FaArrowRightLong } from 'react-icons/fa6';
import gif from '../../assets/85490d6827a15ccbace82a79aafbbe6c.gif'
import { capitalize } from '../actions/CommonActions';
import { useNavigate } from 'react-router-dom';


export default function LoadingModal({open}) {
    const theme = useTheme();
    const navigate = useNavigate()
//   const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setOpen(false);
    // navigate('/result-quiz')
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "10%",
    bgcolor: 'background.paper',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down('sm')]: {
      width: '80%', // Adjust the width for small screens
    },
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{
            backdrop: Backdrop,
          }}
          slotProps={{
            backdrop: {
              sx: {
                // backgroundColor: '#D0D5DD',
                backdropFilter: 'blur(3px)', // Adjust the blur intensity here
              },
            },
          }}
      >
        <Box sx={style}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
            <CircularProgress color="primary" />
            </div>
        </Box>
      </Modal>
    </div>
  );
}