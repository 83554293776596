import React, { useEffect, useState } from "react";
import Root from "../components/Root";
import { CiCalendar } from "react-icons/ci";
import "./dashboardConsultant.css";
import { RiBarChart2Line } from "react-icons/ri";
import { IoIosArrowRoundUp } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Table } from "react-bootstrap";
import { dashboardCV, getAllCvUsers } from "../services";
import { notify } from "../components/actions/CommonActions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CircularProgress } from "@mui/material";

const DashboardConsultant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [cvUsers, setCvUsers] = useState(null);
  const [pageCount, setPageCount] = useState(0); // For total pages
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const metricsAll = async () => {
    // setIsLoading(true);
    await dashboardCV()
      .then(async (res) => {
        // console.log("anon res ", res);
        setMetrics(res);
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    // setIsLoading(false);
  };

  const cvUsersAll = async (page, limit) => {
    await getAllCvUsers(page, limit)
      .then(async (res) => {
        // console.log("cv Users res ", res);
        setCvUsers(res);
        setPageCount(res.totalPages);
        // setMetrics(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const initiate = async () => {
      setIsLoading(true);
      await metricsAll();
      await cvUsersAll(1, 10);
      setIsLoading(false);
    };
    initiate();
  }, []);

  // Handle page change from pagination
  const handlePageClick = async (data) => {
    setIsLoading(true);
    let selectedPage = data.selected + 1; // react-paginate uses 0-based index, so add 1
    setCurrentPage(selectedPage); // Update current page state
    await cvUsersAll(selectedPage, 10); // Fetch the new page data
    setIsLoading(false);
  };


  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div className="bodyBackgroundWhole">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                Welcome David
              </p>
              <p style={{ marginTop: "10px", color: "#475367" }}>
                It’s a sunny day today, we hope you’re taking good care of your
                health 😊
              </p>
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
              }}
            >
              <div
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#F0F2F5",
                  marginRight: "10px",
                  borderRadius: "10px",
                }}
              >
                <CiCalendar size={20} />
              </div>
              <div>
                <p style={{ color: "#475467", fontSize: "12px" }}>
                  Today’s Date
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {moment(new Date()).format("Do MMMM YYYY")}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="boxDesignHolder">
              <RiBarChart2Line size={18} color="#3D89DF" />
              <p
                style={{
                  color: "#667185",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                New Resume
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {metrics?.data?.initiated?.toLocaleString()}
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    padding: "4px",
                    backgroundColor: "#E7F6EC",
                    borderRadius: "5px",
                    display: "flex",
                  }}
                >
                  <IoIosArrowRoundUp color="#036B26" fontSize="14px" />
                  <p style={{ color: "#036B26", fontSize: "12px" }}>8%</p>
                </div>
              </div>
            </div>
            <div className="boxDesignHolder">
              <RiBarChart2Line size={18} color="#3D89DF" />
              <p
                style={{
                  color: "#667185",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                Total Pending Resume
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {metrics?.data?.pending?.toLocaleString()}
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    padding: "4px",
                    backgroundColor: "#E7F6EC",
                    borderRadius: "5px",
                    display: "flex",
                  }}
                >
                  <IoIosArrowRoundUp color="#036B26" fontSize="14px" />
                  <p style={{ color: "#036B26", fontSize: "12px" }}>8%</p>
                </div>
              </div>
            </div>
            <div className="boxDesignHolder">
              <RiBarChart2Line size={18} color="#3D89DF" />
              <p
                style={{
                  color: "#667185",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                Total Resumes
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  {metrics?.data?.total?.toLocaleString()}
                </p>
                <div
                  style={{
                    marginLeft: "10px",
                    padding: "4px",
                    backgroundColor: "#E7F6EC",
                    borderRadius: "5px",
                    display: "flex",
                  }}
                >
                  <IoIosArrowRoundUp color="#036B26" fontSize="14px" />
                  <p style={{ color: "#036B26", fontSize: "12px" }}>8%</p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E4E7EC",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "white",
                padding: "15px 10px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                New Consultation
              </p>
              <p style={{ color: "#667185", fontSize: "14px" }}>
                {/* See all <MdKeyboardArrowRight /> */}
              </p>
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th>Name</th>
                  <th>Email</th>
                  <th>Plan type</th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {cvUsers?.data?.map((each, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: "white", width: "100%" }}
                  >
                    <td>{each?.first_name} {each?.last_name}</td>
                    <td>{each?.email}</td>
                    <td>{each?.plan_id?.plan_type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} // Total pages from API
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardConsultant;
