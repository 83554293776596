import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./uploadCvPricingoptions.css";
import { getPaystackUrl } from "../services";
import LoadingModal from "../components/modals/LoadingModal";
import { notify } from "../components/actions/CommonActions";
import { useNavigate } from "react-router-dom";
import ProceedPaymentModal from "../components/modals/ProceedPaymentModal";

const UploadCVPricingOptions = () => {

  const [email, setEmail] = useState(null)
  const [reviewId, setReviewId] = useState(null)
  const [amt, setAmt] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [paymentLink, setPaymentLink]= useState(null)

  useEffect(()=>{
    const intiate = async () =>{
      const email = await sessionStorage.getItem("emailInfo")
      const reviewId = await sessionStorage.getItem("reviewId")
      setEmail(email)
      setReviewId(reviewId)
    }
    intiate()
  },[])

  const handleOpenLink = (link) => {
    console.log("link ", paymentLink);
    window.location.href = paymentLink
    // window.open(url, "_blank");
// if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
//   alert("Popup blocked. Please allow popups for this site and try again.");
// }
  };
  
  useEffect(()=>{
    const intiate = async () =>{
      if (amt) {
        const sentData = {
          email: email,
          review_id: reviewId,
          amount: amt,
        };
      
        setLoading(true);
      
        try {
          const res = await getPaystackUrl(sentData);
          console.log('res paystack ', res);
          setLoading(false);
      
          const paymentLink = res?.link;
          console.log('payment link ', paymentLink);
          setPaymentLink(paymentLink)
      
          if (paymentLink) {
            // Open the payment link in a new tab
            setShowModal(true)
            // setTimeout(() => {
            //   handleOpenLink(paymentLink);
            // }, 1000);
          } else {
            // notify("danger", "OOPS", "Payment link is not available.");
          }
        } catch (err) {
          console.log('err upload ', err);
          setLoading(false);
          notify("danger", "OOPS", err?.response?.data?.message || "An error occurred.");
        }
      }
    }
    intiate()
  },[amt])
  
  return (
    <div>
      <Header />
      <div className="viewQuizPage">
        <div className="bodyTextHolderFormQuiz">
          <p className="topicUploadCv" style={{ textAlign: "center" }}>
            Select pricing option
          </p>
          <p className="subTextTopicUploadCv">
            Choose the best option that works for you
          </p>
          <div className="pricingOptionsAllDiv">
            <div className="pricingUploadColumnView">
              <div className="transparentPricingUploadCv">
                <img
                  src={require("../assets/flashPrice.png")}
                  width="40px"
                  height="40px"
                />
                <p className="divTopicPricingUpload">Premium</p>
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  ₦1,000
                </p>
                <p
                  className="subTextTopicUploadCv"
                  style={{ marginBottom: "10px" }}
                >
                  One time payment.
                </p>
                <div style={{marginBottom: "30px"}}>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      CV Rebranding
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      Job references based on cv
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      Job community
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      1 time consultation
                    </p>
                  </div>
                </div>
              </div>
              <div className="transparentPricingUploadCv" style={{backgroundColor: "#F9FAFB"}} >
        <button onClick={()=>setAmt(1000)} className='nextButtonQuestionPage' style={{ fontSize: "16px", padding: "10px 40px"}}>Proceed to payment</button>

              </div>
            </div>
            <div className="pricingSecUploadColumnView">
              <div className="transparentPricingUploadCv">
                <img
                  src={require("../assets/flashPrice.png")}
                  width="40px"
                  height="40px"
                />
                <p className="divTopicPricingUpload">Premium +</p>
                <p
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  ₦2,000
                </p>
                <p
                  className="subTextTopicUploadCv"
                  style={{ marginBottom: "10px" }}
                >
                  One time payment.
                </p>
                <div style={{marginBottom: "30px"}}>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      CV Rebranding
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      Cover Letter
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      Job references based on cv
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      Job community
                    </p>
                  </div>
                  <div className="imgMarkTextPricingUploadCv">
                    <img
                      src={require("../assets/markPricingUploadCv.png")}
                      width="24px"
                      height="24px"
                    />

                    <p
                      className="subTextTopicUploadCv"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >
                      2 time consultation
                    </p>
                  </div>
                </div>
              </div>
              <div className="transparentPricingUploadCv" style={{backgroundColor: "#F9FAFB"}} >
        <button  onClick={()=>setAmt(2000)} className='nextButtonQuestionPage' style={{ fontSize: "16px", padding: "10px 40px"}}>Proceed to payment</button>

              </div>
            </div>
          </div>
        <LoadingModal open={loading} />
        <ProceedPaymentModal open={showModal} setOpen={setShowModal} amt={amt} handleOpenLink={handleOpenLink} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadCVPricingOptions;
