import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop, useTheme } from '@mui/material';
import { GoDotFill } from "react-icons/go";
import { FaArrowRightLong } from 'react-icons/fa6';
import gif from '../../assets/85490d6827a15ccbace82a79aafbbe6c.gif'
import { capitalize } from '../actions/CommonActions';


export default function RegisterSuccessful({open, setOpen, createResponse}) {
    const theme = useTheme();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down('sm')]: {
      width: '80%', // Adjust the width for small screens
    },
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{
            backdrop: Backdrop,
          }}
          slotProps={{
            backdrop: {
              sx: {
                // backgroundColor: '#D0D5DD',
                backdropFilter: 'blur(3px)', // Adjust the blur intensity here
              },
            },
          }}
      >
        <Box sx={style}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img src={gif} className='gifRegisterSuccessmodal' alt="Description of gif" />
        </div>
          <h1 style={{textAlign: 'center', marginBottom: '32px'}}>Welcome Onboard, {capitalize(createResponse?.firstName)}! 🎉🥳</h1>
          <div>
            <Typography sx={{fontSize: "14px", width: "80%", margin: 'auto' }}>Welcome to iQuire, where your learning journey begins. We're thrilled to have you on board and can't wait to see you achieve your goals.</Typography>
            <Typography sx={{fontSize: "14px", width: "80%", margin: 'auto' }}>What's Next?</Typography>
            <Typography sx={{fontSize: "14px", width: "80%", margin: 'auto' }}> <GoDotFill style={{color: 'black'}} size={10} /> <span style={{fontWeight: '600'}}>Join Community:</span> Connect with fellow learners, share insights, and grow together. Click here to join our community</Typography>
            <button
                onClick={()=>{
                    // if(accountType) navigate('/signup')
                    window.open('https://chat.whatsapp.com/KnShBNM9r5eG7YZNRcIMUH', '_blank');
                    handleClose()
                }}
              className= "successModalRegisterButtonActive"
            >
              Join Our Community <FaArrowRightLong style={{ marginLeft: "10px" }} />
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}