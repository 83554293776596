

import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getScheduleBookingApi, sendConsultBooking } from "../services";
import "./scheduleUploadCv.css";
import { notify } from "../components/actions/CommonActions";
import LoadingModal from "../components/modals/LoadingModal";
import { useNavigate } from "react-router-dom";

const ScheduleUploadCv = () => {
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [value, onChange] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(value.getMonth());
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([
    "9:00am",
    "10:00am",
    "11:00am",
    "12:00pm",
    "1:00pm",
    "3:00pm"
  ]);

  const timeSlots = [
    "9:00am",
    "10:00am",
    "11:00am",
    "12:00am",
    "1:00pm",
    "3:00pm"
  ];

  const getMonthStartEndDates = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0); // Last day of the month
    return { startDate, endDate };
  };

  const initiate = async (startDate, endDate) => {
    setLoading(true)
    try {
      const res = await getScheduleBookingApi(startDate, endDate);
      console.log("res schedule ", res);
      const busySlots = res?.data.map((slot) => ({
        start: new Date(slot.start),
        end: new Date(slot.end),
      }));
      setAvailableDates(busySlots);
    } catch (err) {
      console.log("err response ", err);
    }
    setLoading(false)
  };

  useEffect(() => {

    // Get the new month's start and end dates
    const { startDate, endDate } = getMonthStartEndDates(value);
    initiate(startDate, endDate);
  }, [value]);

  const getBusyTimesIn12HourFormat = (date, availableDates) => {
    return availableDates
      .filter((slot) => {
        const slotDate = new Date(slot.start).setHours(0, 0, 0, 0);
        const selectedDate = new Date(date).setHours(0, 0, 0, 0);
        return slotDate === selectedDate;
      })
      .map((slot) => convertTo12HourFormat(new Date(slot.start).getHours()));
  };

  const convertTo12HourFormat = (hour) => {
    const period = hour >= 12 ? "pm" : "am";
    const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight
    return `${hour12}:00${period}`;
  };

  const getHourFromTimeSlot = (timeSlot) => {
    const [hour, period] = timeSlot.split(/[: ]/);
    let hourInt = parseInt(hour, 10);
    if (period === "pm" && hourInt !== 12) hourInt += 12; // Convert PM to 24-hour time
    if (period === "am" && hourInt === 12) hourInt = 0; // Handle midnight case
    return hourInt;
  };

  const handleDateChange = (date) => {
    onChange(date);
    console.log("date change ", date);
    const selectedMonth = date.getMonth();
  if (selectedMonth !== currentMonth) {
    setCurrentMonth(selectedMonth);

    // Get the new month's start and end dates
    const { startDate, endDate } = getMonthStartEndDates(date);

    // Call initiate to fetch new data for the selected month
    initiate(startDate, endDate);
  }
    const busyHours = getBusyTimesIn12HourFormat(date, availableDates);
    const filteredSlots = timeSlots.filter((time) => {
      const timeHour = getHourFromTimeSlot(time);
      return !busyHours.includes(time);
    });
    setFilteredTimeSlots(filteredSlots);
  };

  const toggleTimeSlot = (time) => {
    console.log("time prev ", time);
    setSelectedTimes(time);
  };

  const handleSubmit = async (e) =>{
    e.preventDefault()
     // Parse the date string to get the date object
     const date = new Date(value);

     // Convert time string (12-hour format) to 24-hour format
     const [time, modifier] = selectedTimes.split(/(am|pm)/i);
     let [hours, minutes] = time.split(':').map(Number);
 
     if (modifier.toLowerCase() === 'pm' && hours < 12) {
         hours += 12; // convert to 24-hour format
     } else if (modifier.toLowerCase() === 'am' && hours === 12) {
         hours = 0; // convert 12 AM to 0 hours
     }
 
     // Create a new date object with the combined date and time
     const combinedDate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes
  ));
     console.log("combinedDate ", combinedDate);
     const emailUser = await sessionStorage.getItem('emailInfo')
     const reviewId = await sessionStorage.getItem("reviewId")
     const dataSent = {
      email: emailUser,
      date_time: combinedDate,
      review_id: reviewId
     }
     setLoading(true)
     await sendConsultBooking(dataSent)
     .then((res)=>{
      console.log(" res consult ", res);
      notify("success", "Sumitted", "Consultation booked");
      navigate('/uploadcv-pricing');
     })
     .catch((err)=>{
       console.log('err upload ', err);
       notify("danger", "OOPS", err?.response?.data?.message);
     })
     setLoading(false)
  }

  return (
    <div>
      <Header />
      <div className="viewQuizPage">
        <div className="bodyTextHolderFormQuiz">
        <p className="topicUploadCv" style={{ textAlign: "center" }}>
            Schedule consultation
          </p>
          <p className="subTextTopicUploadCv">
            Pick a date and time for consultation.
          </p>
          <div style={{marginTop: "20px"}} className="scheduleUiUploadCv">
            <Calendar onChange={handleDateChange} value={value} />
            <div className="time-slots">
              {filteredTimeSlots.map((time, index) => (
                <button
                  key={index}
                  onClick={() => toggleTimeSlot(time)}
                  className={`time-slot ${
                    selectedTimes.includes(time) ? "selected" : ""
                  }`}
                >
                  {selectedTimes.includes(time) && (
                    <span className="dot"></span>
                  )}
                  {time}
                </button>
              ))}
            </div>
          </div>
          <button onClick={handleSubmit} className='nextButtonQuestionPage' style={{marginTop: "50px", fontSize: "16px", padding: "10px 70px", width: "100%", justifyContent: "center", alignItems: "center"}}>Book consultation</button>
        </div>
        <LoadingModal open={loading} />
      </div>
      <Footer />
    </div>
  );
};

export default ScheduleUploadCv;
