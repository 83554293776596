import axios from "axios";
import { notify } from "../components/actions/CommonActions";
// import { notify } from "../components/actions/CommonActions";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = 30000;


axios.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token") || null;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.log("Request error: ", error);
      return Promise.reject(error);
    }
  );

  async function refreshToken() {
    // Assuming you store refreshToken somewhere in storage
    const refreshToken = localStorage.getItem("refresh_Token");
    const response = await axios.post("/admin/auth/refresh-token", { refresh_token: refreshToken });
    // console.log("this is token response: ", response);
    const accessToken = response.data.accessToken;
  
    // Store the new token
    await localStorage.setItem("token", accessToken);
    await localStorage.setItem('refresh_Token', response.data?.refresh_token)
    await localStorage.setItem('userData', JSON.stringify(response?.data))
  
    return accessToken;
  }


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
  
      // Check if the request was unauthorized and has not already been retried
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark request as retried
  
        try {
          const accessToken = await refreshToken(); // Attempt to refresh token
          // sessionStorage.setItem("token");
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`; // Update the access token in the header
  
          // Retry the original request with the new token
          return axios(originalRequest);
        } catch (refreshError) {
          console.log(refreshError);
          // If token refresh fails, handle accordingly (e.g., redirect to login)
          notify("danger", "Session expired", "Please login again.");
          localStorage.clear(); // Optionally clear session storage
          // window.location.href = "/login"; // Redirect to login page
          return Promise.reject(refreshError);
        }
      }
    }
  )
      

  export async function createUser(values) {
    const { data } = await axios.post(`user/register`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function formQuiz(values) {
    const { data } = await axios.post(`session/start`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function questionsApi(values, page, limit) {
    const { data } = await axios.post(`question/get?page=${page}&limit=${limit}`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function anonApi(values) {
    const { data } = await axios.post(`session/start/anon`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function questionAnswerApi(values) {
    const { data } = await axios.post(`question/submit`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function uploadCvApi(values) {
    const { data } = await axios.post(`upload`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`,
            "Content-Type": "miltipart/form-data"
        }
    });
    return data;
  }

  export async function sendUploadInfoApi(values) {
    const { data } = await axios.post(`cv/add`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function getScheduleBookingApi(startDate, endDate) {
    const { data } = await axios.get(`consult/booked-meetings?startDate=${startDate}&endDate=${endDate}`,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function sendConsultBooking(values) {
    const { data } = await axios.post(`consult/book`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function getPaystackUrl(values) {
    const { data } = await axios.post(`payment/initiate`, values,{
        headers:{
            'x-api-key' : `${process.env.REACT_APP_X_API_KEY}`
        }
    });
    return data;
  }

  export async function loginConsultant(values) {
    const { data } = await axios.post(`admin/auth/sign-in`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function forgotPasswordConsultant(values) {
    const { data } = await axios.post(`admin/auth/otp`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function validateEmailConsultant(values) {
    const { data } = await axios.post(`admin/auth/validate-otp`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function changeUserInfo(values) {
    const { data } = await axios.post(`admin/auth/update-user-info`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function allAdminUsers( page , limit) {
    const { data } = await axios.get(`admin/auth/get-all-user?page=${page}&limit=${limit}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function createUserInvite(values) {
    const { data } = await axios.post(`admin/auth/create`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function setPasswordInvite(values) {
    const { data } = await axios.post(`admin/auth/set-password`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function setPasswordAuth(values) {
    const { data } = await axios.post(`admin/auth/forgot-password`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function dashboardCV() {
    const { data } = await axios.get(`admin/dashboard/metrics`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }


  export async function deactivateUserid(id) {
    const { data } = await axios.get(`admin/auth/deactivate-user?user_id=${id}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function activateUserid(id) {
    const { data } = await axios.get(`admin/auth/activate-user?user_id=${id}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function getAllRoles() {
    const { data } = await axios.get(`role`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function getAllCvUsers(page, limit) {
    const { data } = await axios.get(`admin/dashboard/cv-user?page=${page}&limit=${limit}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function getAllCvReviews(page, limit) {
    const { data } = await axios.get(`cv/get-all-cv-reviews?page=${page}&limit=${limit}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function getAllUserManage(page, limit) {
    const { data } = await axios.get(`user/get-registered-users?page=${page}&limit=${limit}`,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function uploadProfilePic(values) {
    const { data } = await axios.post(`admin/auth/update-picture`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function doneConsultation(values) {
    const { data } = await axios.post(`consult/update-meeting`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }

  export async function doneCvReview(values) {
    const { data } = await axios.post(`consult/update-cv-reviewed`, values,{
        headers:{
            "Content-type": "application/json"
        }
    });
    return data;
  }