import React, { useEffect, useState } from "react";
import Root from "../components/Root";
import { CiCalendar } from "react-icons/ci";
import "./dashboardConsultant.css";
import { RiBarChart2Line } from "react-icons/ri";
import { IoIosArrowRoundUp } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Table } from "react-bootstrap";
import { LuImagePlus } from "react-icons/lu";
import { activateUserid, allAdminUsers, changeUserInfo, createUserInvite, deactivateUserid, getAllRoles, uploadCvApi, uploadProfilePic } from "../services";
import { capitalize, notify } from "../components/actions/CommonActions";
import { HiDotsVertical } from "react-icons/hi";
import AddTeamMemberModal from "../components/modals/AddTeamMemberModal";
import LoadingModal from "../components/modals/LoadingModal";
import moment from "moment";
import DeactivateUser from "../components/modals/DeactivateUser";
import ActivateUser from "../components/modals/ActivateUser";
import ReactPaginate from "react-paginate";

const SettingsConsultant = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [inviteFullName, setInviteFullName] = useState("");
  const [inviteRole, setInviteRole] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [allUsers, setAllUsers] = useState(null)
  const [indexPressed, setIndexPressed] = useState(null)
  const [show, setShow] = useState(false)
  const [showDeactivate, setShowDeactivate] = useState(null)
  const [showActivate, setShowActivate] = useState(null)
  const [userId, setUserId] = useState(null)
  const [roles, setRoles] = useState(null)
  const [pageCount, setPageCount] = useState(0); // For total pages
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false);
  const initiate = async (page, limit) => {
    setIsLoading(true);
    await allAdminUsers(page, limit)
      .then(async (res) => {
        console.log("anon res ", res);
        setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setIsLoading(false);
  };

  const gettingRoles = async () => {
    await getAllRoles()
      .then(async (res) => {
        console.log("roles res ", res);
        setRoles(res?.data)
        setPageCount(res.totalPages);
        // setAllUsers(res)
        // notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const userInfo = localStorage.getItem("userData");
    const parsedUser = JSON.parse(userInfo);
    setUser(parsedUser);
    if (parsedUser) {
      setFirstName(parsedUser?.user?.firstName);
      setLastName(parsedUser?.user?.lastName);
    }
    initiate(1, 10);
    gettingRoles()
    console.log("user role ", user?.user?.role);
    // !isAuth ? navigate("/all-mandates") : navigate("/login");
  }, []);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);

  const handleSubmitUserChange = async (e) => {
    e.preventDefault();
    setOpen(false);
    setLoading(true);
    const data = {
      user_picture: "",
      first_name: firstName,
      last_name: lastName,
    };
    await changeUserInfo(data)
      .then(async (res) => {
        console.log("anon res ", res);
        notify("success", "Profile updated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    let name = inviteFullName?.split(" ");
    console.log("name ", name);
    const data = {
      first_name: name[0],
      last_name: name[1],
      email: inviteEmail,
      role: inviteRole,
    };
    // console.log("data ", data);
    setLoading(true);
    await createUserInvite(data)
      .then(async (res) => {
        console.log("anon res ", res);
        notify("success", "User invited", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
    setLoading(false);
  };

  const handleSubmitDeactivate = async() =>{
    await setShowDeactivate(false)
    await setLoading(true)
    await deactivateUserid(userId)
      .then(async (res) => {
        notify("success", "User deactivated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
      initiate(1, 10);
      setLoading(false)
  }

  const handleSubmitActivate = async() =>{
    await setShowActivate(false)
    await setLoading(true)
    await activateUserid(userId)
      .then(async (res) => {
        notify("success", "User activated", res?.message);
      })
      .catch((err) => {
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      });
      initiate(1, 10);
      setLoading(false)
  }

  const handlePageClick = async (data) => {
    setIsLoading(true);
    let selectedPage = data.selected + 1; // react-paginate uses 0-based index, so add 1
    setCurrentPage(selectedPage); // Update current page state
    await initiate(selectedPage, 10); // Fetch the new page data
    setIsLoading(false);
  };


  const handleSubmitPic = async (event) => {
    const selectedFile = event.target.files[0];
        const formData = new FormData()
        formData.append("file", selectedFile)
        setLoading(true)
        
        try {
            const res = await uploadCvApi(formData)
            console.log('res upload ', res)
            let upload = {
              user_picture: res?.data?.file_url
          }
          await uploadProfilePic(upload)
          .then((res)=>{
            notify("success", "Profile pic updated", "Please login again to view change.")
          })
          .catch((err) =>{
            notify("danger", "OOPS", err?.response?.data?.message)
          })
            // setPassedInfo(prev => ({
            //     ...prev,
            //     cv_url: res?.data?.file_url
            // }))
        } catch (err) {
            console.log('err upload ', err)
            notify("danger", "OOPS", err?.response?.data?.message)
        } finally {
            setLoading(false)
        }
}

  return (
    <>
    {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "100vh",
          }}
        >
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
    <div className="bodyBackgroundWhole">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <p style={{ fontSize: "24px", fontWeight: "bold" }}>Setting</p>
        </div>
        <div></div>
      </div>
      <div
        style={{
          marginTop: "30px",
          borderBottom: "1px solid #E4E7EC",
          display: "flex",
        }}
      >
        <p
          onClick={() => setStep(1)}
          style={{
            color: step == 1 ? "#0D5EBA" : "#667185",
            borderBottom: step == 1 ? "1.5px solid #0D5EBA" : "",
            padding: "15px",
            fontWeight: "600",
          }}
        >
          Profile
        </p>
        {user?.user?.role == "super admin" && <p
          onClick={() => setStep(2)}
          style={{
            color: step == 2 ? "#0D5EBA" : "#667185",
            borderBottom: step == 2 ? "1.5px solid #0D5EBA" : "",
            padding: "15px",
            fontWeight: "600",
          }}
        >
          Team Management
        </p>}
      </div>
      {step == 1 && (
        <>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              paddingBottom: "10px",
              borderBottom: "1px solid #E4E7EC",
            }}
          >
            <div>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                Upload photo
              </p>
              <label
                htmlFor="profilePic"
                style={{
                  color: "#1671D9",
                  border: "1px solid #1671D9",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  marginTop: "30px",
                  cursor: "pointer",
                }}
              >
                <LuImagePlus size={20} style={{ marginRight: "10px" }} /> Change
                Photo
              </label>
              <input id="profilePic" onChange={handleSubmitPic} type="file" style={{ display: "none" }} />
            </div>
            {!user?.user?.profilePicture && <img
              src={require("../assets/profilePic.png")}
              style={{ marginLeft: "100px" }}
              width="120px"
              height="120px"
            />}
            {user?.user?.profilePicture && <img
              src={user?.user?.profilePicture}
              style={{ marginLeft: "100px", borderRadius: "50%" }}
              width="120px"
              height="120px"
            />}
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <div>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                {" "}
                Personal Information
              </p>
              <p
                style={{ fontSize: "14px", color: "#667185", marginTop: "5px" }}
              >
                Update your personal details here.
              </p>
              <button
                onClick={handleSubmitUserChange}
                style={{
                  marginTop: "15px",
                  backgroundColor: "#1671D9",
                  border: "none",
                  padding: "10px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Save Changes
              </button>
            </div>
            <div style={{ marginLeft: "80px" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <p style={{ fontSize: "14px", fontWeight: "500" }}>
                    First name
                  </p>
                  <input
                    value={firstName}
                    onChange={handleFirstNameChange}
                    type="text"
                    style={{
                      border: "1px solid #D0D5DD",
                      padding: "10px",
                      width: "300px",
                      borderRadius: "5px",
                      marginTop: "5px",
                    }}
                  />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <p style={{ fontSize: "14px", fontWeight: "500" }}>
                    Last name
                  </p>
                  <input
                    value={lastName}
                    onChange={handleLastNameChange}
                    type="text"
                    style={{
                      border: "1px solid #D0D5DD",
                      padding: "10px",
                      width: "300px",
                      borderRadius: "5px",
                      marginTop: "5px",
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  Email address
                </p>
                <input
                  type="text"
                  value={user?.user?.email}
                  style={{
                    border: "1px solid #D0D5DD",
                    padding: "10px",
                    width: "640px",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>Role</p>
                <input
                  type="text"
                  value={user?.user?.role}
                  style={{
                    border: "1px solid #D0D5DD",
                    padding: "10px",
                    width: "640px",
                    borderRadius: "5px",
                    marginTop: "5px",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {step == 2 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>User List</p>
            </div>
            <div>
              <button
                onClick={() => setOpen(true)}
                style={{
                  marginTop: "15px",
                  backgroundColor: "#0D5EBA",
                  border: "none",
                  padding: "10px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Invite new member
              </button>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E4E7EC",
              borderRadius: "5px",
              marginTop: "20px",
            }}
          >
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                  {/* Table header cells */}
                  {/* Modify according to your data structure */}
                  <th>Name</th>
                  <th>Date added</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {allUsers?.data?.map((item, index) => <tr key={index} style={{ backgroundColor: "white", width: "100%" }}>
                  <td>{item?.fullName}</td>
                  <td>{moment(item?.createdAt).format("MMM DD, YYYY | hh:mmA")}</td>
                  <td>{item?.role?.replace(/_/g, " ")}</td>
                  <td><span style={{padding: "5px", backgroundColor: item?.status == "active"? "#E7F6EC" : item?.status == "invited"? "#E3EFFC" : "#FBEAE9", borderRadius: "10px", color: item?.status == "active"? "#036B26": item?.status == "invited"? "#04326B": "#9E0A05", fontWeight: "500"}}>{capitalize(item?.status)}</span></td>
                  <td style={{position: "relative"}} onClick={()=> {
                    setIndexPressed(index)
                    setShow(!show)
                    setUserId(item?._id)
                    }}>
                    <span
                      style={{
                        border: "1px solid #E4E7EC",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        zIndex: "5"
                      }}
                    >
                      <HiDotsVertical />
                    </span>
                      {index == indexPressed && show && <div style={{position: "absolute", padding: "5px 10px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", zIndex: "10", backgroundColor: "white", left: "-30px"}}>
                        {item?.status != "active" && <p style={{fontSize: "14px", padding: "5px", cursor: "pointer"}} onClick={()=> setShowActivate(!showActivate)}>Activate User</p>}
                        {item?.status == "active" && <p style={{fontSize: "14px", padding: "5px", cursor: "pointer"}} onClick={()=> setShowDeactivate(!showDeactivate)}>Deactivate User</p>}
                      </div>}
                  </td>
                </tr>)}
              </tbody>
            </table>
            <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} // Total pages from API
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
          </div>

          <AddTeamMemberModal
            open={open}
            setOpen={setOpen}
            handleInvite={handleInvite}
            inviteFullName={inviteFullName}
            inviteEmail={inviteEmail}
            inviteRole={inviteRole}
            setInviteFullName={setInviteFullName}
            setInviteEmail={setInviteEmail}
            setInviteRole={setInviteRole}
            roles={roles}
          />

          <DeactivateUser open={showDeactivate} setOpen={setShowDeactivate} handleInvite={handleSubmitDeactivate} />
          <ActivateUser open={showActivate} setOpen={setShowActivate} handleInvite={handleSubmitActivate} />
        </>
      )}
      <LoadingModal open={loading} />
      <LoadingModal open={isLoading} />
    </div>
      )
                    }
    </>
  );
};

export default SettingsConsultant;
