import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Chart from "react-apexcharts";
import "./quizresult.css";
import muslimWoman from '../assets/muslimWomanImg.png';
import cvWritingImg from '../assets/cvWritingImg.png';
import { Link, useLocation } from "react-router-dom";

const QuizResult = () => {
  const location = useLocation();
  // Destructure the user object from the state passed via navigate
  const result = location.state?.result || { categoryResults: {} };  // This ensures no error if state is undefined
  // console.log('result ', result);

  // Calculate total score and percentage
  const totalQuestions = 40; // You can adjust this to match the total number of questions
  const totalCorrect = result.totalSuccess; // Total correct answers
  const totalIncorrect = result.totalFailed; // Total incorrect answers
  const percentage = ((totalCorrect / totalQuestions) * 100).toFixed(2); // Calculate percentage

  // Ensure category results are safe to access
  const { success: hardSkillSuccess = 0, failed: hardSkillFailed = 0 } = result?.categoryResults?.["hard skills"] || {};
  const { success: softSkillSuccess = 0, failed: softSkillFailed = 0 } = result?.categoryResults?.["soft skills"] || {};
  const { success: digitalSkillSuccess = 0, failed: digitalSkillFailed = 0 } = result?.categoryResults?.["digital skills"] || {};
  const { success: personalitySkillSuccess = 0, failed: personalitySkillFailed = 0 } = result?.categoryResults?.["personality test"] || {};
  const { success: generalSkillSuccess = 0, failed: generalSkillFailed = 0 } = result?.categoryResults?.["general skills"] || {};
  const noAnswerHardSkill = 8 - (hardSkillFailed + hardSkillSuccess)
  const noAnswerSoftSkill = 8 - (softSkillFailed + softSkillSuccess)
  const noAnswerDigitalSkillSuccess = 8 - (digitalSkillFailed + digitalSkillSuccess)
  const noAnswerPersonalitySkill = 8 - (personalitySkillFailed + personalitySkillSuccess)
  const noAnswerGeneralSkill = 8 - (generalSkillFailed + generalSkillSuccess)
  // console.log('personality fail ', personalitySkillFailed);
  // Chart configurations for different categories
  const chartOptions = {
    chart: {
      id: "apexchart-example",
    },
    labels: ["Correct Answers", "Wrong Answers", "No Answers"],
    colors: ["#5FC381", "#875BF7", "#C6DDF7"], // Green for correct, red for wrong
    legend: {
      position: "bottom",
    },
  };
  
  const excellent = [
    "Strengths: Your performance showcases a high level of expertise and understanding. You are well-prepared for advanced tasks and responsibilities.    ",
    "Next Steps: Continue to build on your strengths and consider taking on leadership roles or more complex projects. Explore our courses (free and discounted ) gain certifications to further enhance your skill set.    ",
    "Opportunities: Employers will view you as a top candidate, making you highly competitive in the job market. Be prepared to highlight your skills in interviews and your resume.    "
  ]

  const good = [
    "Strengths:  You have a strong foundation and have demonstrated competence in key areas. This makes you a valuable candidate for many roles.    ",
    "Next Steps: Identify areas where you can improve and focus on those. Consider additional training or coursework to elevate your skills from good to excellent.    ",
    "Opportunities: Make sure to communicate your strengths effectively to potential employers.    "
  ]



  return (
    <div>
      <Header />
      <div className="viewQuizPage bodyHolderQuizResult">
        <div className="bodyTextResultQuizHolder">
          <p className="topicStartQuiz">
            Your test result:{" "}
            <span style={{ color: "#0F973D" }}>{totalCorrect}/{totalQuestions} ({percentage}%)</span>
          </p>
          <p className="stopTimeText" style={{ textAlign: "center" }}>
            Completed on July 9, 2024
          </p>

          <div style={{backgroundColor: "#F2F6FB"}}>
          <p className="topicStartQuiz">Score breakdown</p>

          <div className="graphHoldersResultPage">
            {/* Digital Skills */}
            <div>
              <p className="topicStartQuiz" style={{ fontSize: "18px" }}>
                Digital Skill
              </p>
              <Chart
                options={chartOptions}
                series={[digitalSkillSuccess, digitalSkillFailed, noAnswerDigitalSkillSuccess]} // Use result data for digital skills
                type="donut"
                height={280}
              />
            </div>

            {/* Hard Skills */}
            <div>
              <p className="topicStartQuiz" style={{ fontSize: "18px" }}>
                Hard Skill
              </p>
              <Chart
                options={chartOptions}
                series={[hardSkillSuccess, hardSkillFailed, noAnswerHardSkill]} // Use result data for hard skills
                type="donut"
                height={280}
              />
            </div>

            
          </div>

          <div className="graphHoldersResultPage">
            {/* Soft Skills */}
            <div>
              <p className="topicStartQuiz" style={{ fontSize: "18px" }}>
                Soft Skill
              </p>
              <Chart
                options={chartOptions}
                series={[softSkillSuccess, softSkillFailed, noAnswerSoftSkill]} // Use result data for soft skills
                type="donut"
                height={280}
              />
            </div>

            {/* Personality Skills */}
            <div>
              <p className="topicStartQuiz" style={{ fontSize: "18px" }}>
                Personality Skill
              </p>
              <Chart
                options={chartOptions}
                series={[personalitySkillSuccess, personalitySkillFailed, noAnswerPersonalitySkill]} // Use result data for personality skills
                type="donut"
                height={280}
              />
            </div>
          </div>
          <div className="graphHoldersResultPage">
            {/* Soft Skills */}
            <div>
              <p className="topicStartQuiz" style={{ fontSize: "18px" }}>
                General Skill
              </p>
              <Chart
                options={chartOptions}
                series={[generalSkillSuccess, generalSkillFailed, noAnswerGeneralSkill]} // Use result data for soft skills
                type="donut"
                height={280}
              />
            </div>
          </div>
          </div>

{totalCorrect >= 35 ? <div style={{marginBottom: "30px", padding: "0px 40px"}}>
          <p className="topicResultSummaryQuiz">Excellent:</p>
          <p className="textColorQuiz">Congratulations! You've demonstrated exceptional proficiency.</p>
          <p className="textColorQuiz">Your score places you in the top tier of candidates. This indicates a strong mastery of the skills and knowledge required in your field. Here’s what this means for you:</p>
          {excellent?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}
          </div>:
          totalCorrect >= 20 ?
          <div style={{marginBottom: "30px", padding: "0px 40px"}}>
          <p className="topicResultSummaryQuiz">Good:</p>
          <p className="textColorQuiz">Good job! You've demonstrated solid proficiency.</p>
          <p className="textColorQuiz">Your score reflects a good grasp of the essential skills and knowledge required but you are do better. While there are areas for improvement, you are well on your way to mastering the necessary competencies.</p>
          {good?.map((each, index) => (
            <div key={index} className="instructionsHolder">
              {/* <GoDotFill style={{color: '#667185'}} /> */}
              <span
                style={{
                  padding: "3px",
                  backgroundColor: "#667185",
                  borderRadius: "50%",
                  marginTop: "5px",
                }}
              ></span>
              <p className="textColorQuiz">{each}</p>
            </div>
          ))}
          </div>:
          <div style={{marginBottom: "30px", padding: "0px 40px"}}>
          <p className="topicResultSummaryQuiz">Fair:</p>
          <p className="textColorQuiz">You’re on the right track! There's room for growth.</p>
          <p className="textColorQuiz">Your score indicates that you have a basic understanding of the skills and knowledge required but there are significant areas that need improvement.</p>
          </div>}

          <p className="welcomeText">Check out some of our features</p>

          <div className="otherResoucesQuizresultView">
            <div className="otherResoucesTextHolderResultPage">
              <p className="topicStartQuiz" style={{ textAlign: 'left' }}>Explore our courses on iQuire</p>
              <p style={{ color: "#344054", fontSize: "14px", lineHeight: 1.5 }}>
              Master the digital tools essential for success in today’s tech-driven world. Learn how to use Canva for design projects and Excel for accounting tasks, empowering you to enhance your business operations or career with practical, in-demand skills.
              </p>
              <div style={{ display: "flex", justifyContent: 'flex-start', marginTop: "32px" }}>
                <a className="startQuizButtonQuestionPage" href="https://www.iquire.co/" style={{ textDecoration: "none", marginTop: '0px' }}>
                  Explore course
                </a>
              </div>
            </div>
            <img src={muslimWoman} className="imgSizeViewResult" alt="Muslim Woman" />
          </div>

          <div className="otherResoucesQuizresultView">
            <img src={cvWritingImg} className="imgSizeViewResult" alt="CV Writing" />
            <div className="otherResoucesTextHolderResultPage">
              <p className="topicStartQuiz" style={{ textAlign: 'left' }}>CV Writing</p>
              <p style={{ color: "#344054", fontSize: "14px", lineHeight: 1.5 }}>
              Master the digital tools essential for success in today’s tech-driven world. Learn how to use Canva for design projects and Excel for accounting tasks, empowering you to enhance your business operations or career with practical, in-demand skills.
              </p>
              <div style={{ display: "flex", justifyContent: 'flex-start', marginTop: "32px" }}>
                <Link className="startQuizButtonQuestionPage" to='/upload-cv' style={{ textDecoration: "none", marginTop: '0px' }}>
                  CV Review
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuizResult;
