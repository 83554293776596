import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './quizCategoryPage.css'
import { PiGraduationCapBold } from "react-icons/pi";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import { IoPersonOutline } from 'react-icons/io5';
import { FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { anonApi } from '../services';
import { notify } from '../components/actions/CommonActions';
import LoadingModal from '../components/modals/LoadingModal';

const QuizCategoryPage = () => {
  const navigate = useNavigate()
  const [category, setCategory] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () =>{
    let data = {
      level: category
    }
    setLoading(true)
    if(category){
      localStorage.setItem('quizCategory', category)
      await anonApi(data)
      .then(async (res)=>{
        // console.log('anon res ', res);
        await localStorage.setItem('sessionIdSkillQuiz', res?.test_id)
        navigate('/start-quiz')
      })
      .catch((err)=>{
        // console.log('err res anon ', err);
        notify("danger", "OOPS", err?.response?.data?.message);
      })
    }
    else{
      notify("danger", "OOPS", "Select a category.");
    }
    setLoading(false)
  }

  const handleSelect = async (picked) =>{
    setCategory(picked)
  }

  return (
    <div>
        <Header />
        <div className='quizCategoryTextHolder'>
        <p className='topicCategoryQuiz'>Please select what applies</p>
        <div className='categoryHolderQuiz'>
            <div className='categoryDivSelectView' onClick={()=> handleSelect('entry')}>
                <div className='capSelectViewHolder'>
                <div className='capDivIconView'>
                <PiGraduationCapBold size={20} />
                </div>
                {category == 'entry' ? <IoIosRadioButtonOn color="#0CAF77" size={20} /> : <IoIosRadioButtonOff color="#D0D5DD" size={20} />}
                </div>
                <p className='categoryTopicSelectTextQuiz'>Entry level</p>
                <p className='categorySubtopicSelectTextQuiz'>For fresh graduates and people with 0-1 year of experience.</p>
            </div>

            <div className='categoryDivSelectView' onClick={()=> handleSelect('experienced')}>
                <div className='capSelectViewHolder'>
                <div className='capDivIconView'>
                <IoPersonOutline size={20} />
                </div>
                {category == 'experienced' ? <IoIosRadioButtonOn color="#0CAF77" size={20} /> : <IoIosRadioButtonOff color="#D0D5DD" size={20} />}
                </div>
                <p className='categoryTopicSelectTextQuiz'>Experience level</p>
                <p className='categorySubtopicSelectTextQuiz'>For people with 2 years for experience and above</p>
            </div>
        </div>
        <button
                    className="quizCategoryPageButton"
                    type="submit"
                    onClick={handleSubmit}
                    //   disabled={isSubmitting}
                  >
                    Next <FaArrowRight style={{marginLeft: '8px'}} />
                  </button>
        </div>
        <LoadingModal open={loading} />
        <Footer />
    </div>
  )
}

export default QuizCategoryPage