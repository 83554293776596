import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './formquiz.css'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6'
import { useLocation, useNavigate } from 'react-router-dom'
import { formQuiz, questionAnswerApi } from '../services'
import { notify } from '../components/actions/CommonActions'
import QuizSubmitLoadingModal from '../components/modals/QuizSubmitLoadingModal'
import SkillTestModal from '../components/modals/SkillTestModal'

const FormQuiz = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formField, setFormField] = useState({})
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
  const [createResponse, setCreateResponse] = React.useState(null);
  const [result, setResult] = useState(null)

  // Destructure the user object from the state passed via navigate
  const { selectedOption } = location.state || {}; // This ensures no error if state is undefined
//   console.log('selectedOption form page ', selectedOption);
    const handleChange = (e) =>{
        const {name, value} = e.target
        setFormField((prev) => ({...prev, [name]: value}))
    }
    const handleSubmit = async (e) =>{
        e.preventDefault()
        setLoading(true)
        let testId = await localStorage.getItem("sessionIdSkillQuiz");
        formField.answers = selectedOption
        formField.test_id = testId
        console.log('submit form quiz ', formField);
        await questionAnswerApi(formField)
        .then(async (res)=>{
            // console.log('log res quiz ', res);
            setResult(res)
            setOpen(true)
            // notify("success", "Successful", "successful.");
            // await localStorage.setItem('sessionIdSkillQuiz', res?.test_id)
            // navigate('/question-page')
        })
        .catch((err)=>{
            console.log('err response ', err);
        })
        setLoading(false)
    }
  return (
    <div>
        <Header />
        <div className="viewQuizPage">
        <div className="bodyTextHolderFormQuiz">
        <p className="topicFormQuiz" style={{textAlign: 'center', marginBottom: "50px"}}>Fill in your details below</p>
        <form className='formFieldFormQuiz' onSubmit={handleSubmit}>
            <p style={{color: "#101928"}}>Full Name</p>
            <input type='text' name='full_name' onChange={handleChange} className='inputFieldFormQuiz' required placeholder='Henry Mide' />
            <p style={{color: "#101928"}}>Email</p>
            <input type='email' name='email' onChange={handleChange} className='inputFieldFormQuiz' required placeholder='example@mail.com' />
            <p style={{color: "#101928"}}>Job role</p>
            <input type='text' name='job_role' required onChange={handleChange} className='inputFieldFormQuiz' placeholder='Job role' />
            <div style={{marginTop: '50px', display: 'flex', justifyContent:"flex-end"}}>
                <button type="submit" className='nextButtonQuestionPage'>Submit <FaArrowRight style={{marginLeft: '8px'}} /></button>
            </div>
        </form>
        </div>
        <QuizSubmitLoadingModal open={loading} />
        </div>

        <SkillTestModal
        open={open}
        setOpen={setOpen}
        createResponse={createResponse}
        result={result}
      />


        <Footer />
    </div>
  )
}

export default FormQuiz